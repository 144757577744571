import React, { useState, useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import topshotImage from "../logos/TopShot.png";
import flow from "../logos/flow.svg";
import dapperlabs from "../logos/dapperlabs.svg";
import axiomzen from "../logos/axiomzen.svg";
import cryptokitties from "../logos/cryptokitties.svg";
import { content } from "../data/timeline";
import FlowTimeline from "../logos/FlowTimeline.svg";
import Filter from "./TimelineFilter";
import TimeCircuits from "./TimeCircuits";

// Mapping internal type names to human-readable labels
const typeLabels = {
  NewProduct: "New Product",
  NewFeature: "New Feature",
  IRLEvent: "IRL Event",
  Partnership: "Partnership",
  Funding: "Funding",
  Personnel: "Personnel",
  Issue: "Issue",
  Merchandise: "Merchandise",
};

const getIconDetails = (iconType) => {
  switch (iconType) {
    case "TopShot":
      return { background: "white", icon: topshotImage };
    case "Flow":
      return { background: "white", icon: flow };
    case "DapperLabs":
      return { background: "white", icon: dapperlabs };
    case "AxiomZen":
      return { background: "white", icon: axiomzen };
    case "CryptoKitties":
      return { background: "white", icon: cryptokitties };
    default:
      return { background: "gray", icon: null };
  }
};

// Group entries by year, month, and iconType
const groupEntries = (entries) => {
  const groupedEntries = {};

  entries.forEach((entry) => {
    const { year, month, iconType } = entry;
    const key = `${year}-${month}-${iconType}`;

    if (!groupedEntries[key]) {
      groupedEntries[key] = {
        year,
        month,
        iconType,
        items: [],
      };
    }

    groupedEntries[key].items.push(entry);
  });

  return Object.values(groupedEntries);
};

const Timeline = () => {
  const allProjects = [
    "AxiomZen",
    "DapperLabs",
    "CryptoKitties",
    "Flow",
    "TopShot",
  ];
  const [selectedProjects, setSelectedProjects] = useState(allProjects);
  const [isTimeMachineOpen, setIsTimeMachineOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const monthMapping = {
    JAN: "January",
    FEB: "February",
    MAR: "March",
    APR: "April",
    MAY: "May",
    JUN: "June",
    JUL: "July",
    AUG: "August",
    SEP: "September",
    OCT: "October",
    NOV: "November",
    DEC: "December",
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const handleDateSubmit = ({ year, month }) => {
    const fullMonth = monthMapping[month.toUpperCase()];

    if (!fullMonth) {
      console.error("Invalid month:", month);
      return;
    }

    const submittedDate = new Date(`${fullMonth} 1, ${year}`);
    let foundElement = null;
    let closestElement = null;
    let lastElement = null;

    document
      .querySelectorAll(".vertical-timeline-element")
      .forEach((element) => {
        const dateElement = element.querySelector(
          ".vertical-timeline-element-date"
        );

        if (dateElement) {
          const elementDateText = dateElement.textContent.trim();
          const elementDate = new Date(`${elementDateText} 1`);

          if (
            !lastElement ||
            elementDate >
              new Date(
                lastElement
                  .querySelector(".vertical-timeline-element-date")
                  .textContent.trim()
              )
          ) {
            lastElement = element;
          }

          if (!foundElement && elementDate >= submittedDate) {
            foundElement = element;
          }

          if (elementDate > submittedDate && !closestElement) {
            closestElement = element;
          }
        }
      });

    if (foundElement) {
      foundElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (closestElement) {
      closestElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (
      submittedDate >
      new Date(
        lastElement
          .querySelector(".vertical-timeline-element-date")
          .textContent.trim()
      )
    ) {
      lastElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      const firstElement = document.querySelector(".vertical-timeline-element");
      if (firstElement) {
        firstElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const handleFilterChange = (iconType, isChecked) => {
    setSelectedProjects((prev) =>
      isChecked
        ? [...prev, iconType]
        : prev.filter((project) => project !== iconType)
    );
  };

  const groupedContent = groupEntries(content);

  return (
    <div className="bg-gray-300 p-3">
      <div className="bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 animate-slow-wave p-2 rounded-lg inline-flex items-center justify-center mb-4">
        <img src={FlowTimeline} alt="Flow Timeline" className="h-10" />
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="text-gray-700 mb-4">
          The FlowTimeline provides a high-level overview of significant
          innovations and events within the Flow blockchain ecosystem. Specific
          Digital Collectible releases are out-of-scope unless part of a
          partnership or demonstrate innovation.
        </p>
        <p className="text-gray-700">
          Join the discussion and provide feedback on our{" "}
          <a
            href="https://discord.gg/5MU4ngQQ"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            Discord
          </a>
          .
        </p>
      </div>

      {/* {!isMobile && (
        <>
          <div
            className="cursor-pointer bg-white p-4 rounded-lg shadow-md mb-4 flex items-center"
            onClick={() => setIsTimeMachineOpen(!isTimeMachineOpen)}
          >
            <svg
              className={`transform transition-transform duration-200 ${
                isTimeMachineOpen ? "" : "rotate-90"
              } h-6 w-6`}
              fill="black"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
            <h3 className="text-lg font-bold text-gray-800 ml-2">
              Experimental Time Circuits
            </h3>
          </div>

          {isTimeMachineOpen && (
            <div className="bg-white p-4 rounded-lg shadow-md mb-4">
              <TimeCircuits onDateSubmit={handleDateSubmit} />{" "}
            </div>
          )}
        </>
      )} */}

      <Filter
        iconTypes={allProjects}
        selectedIconTypes={selectedProjects}
        onFilterChange={handleFilterChange}
      />

      <VerticalTimeline>
        {groupedContent
          .filter((entry) => selectedProjects.includes(entry.iconType))
          .map((group, idx) => {
            const { background, icon } = getIconDetails(group.iconType);
            return (
              <VerticalTimelineElement
                key={`${group.year}-${group.month}-${group.iconType}-${idx}`}
                // Date is moved to the top left inside content area
                iconStyle={{
                  background,
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                contentStyle={{
                  background: "#f9fafb",
                  color: "#333",
                  paddingTop: "1rem",
                }} // Adjust padding for date
                contentArrowStyle={{ borderRight: "10px solid #f9fafb" }}
                icon={
                  <div className="w-full h-full flex justify-center items-center">
                    <img
                      src={icon}
                      alt={group.iconType}
                      className="object-contain w-full h-full rounded-full"
                    />
                  </div>
                }
                className="timeline-element"
              >
                <div className="text-gray-600 text-sm font-semibold mb-2">
                  {/* Displaying the date */}
                  {`${group.month} ${group.year}`}
                </div>

                {group.items.map((item, idx) => (
                  <div key={idx}>
                    <div className="flex items-center mb-2">
                      {/* Tag button for type, ensure label is consistent size */}
                      <span className="inline-block bg-gray-200 text-gray-800 text-xs font-semibold px-2 py-1 rounded-full mr-2 whitespace-nowrap">
                        {typeLabels[item.type] || item.type}
                      </span>
                      <h4 className="font-bold text-blue-600 text-lg">
                        {item.title}
                      </h4>
                    </div>
                    <p className="text-gray-700 text-base mb-2 inline">
                      {item.details}
                      {/* "Read more" link is appended immediately after details with no gaps */}
                      {item.link && (
                        <span>
                          {" "}
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            Read more here
                          </a>
                        </span>
                      )}
                    </p>
                  </div>
                ))}
                <div className="mb-4"></div>
              </VerticalTimelineElement>
            );
          })}
      </VerticalTimeline>
    </div>
  );
};

export default Timeline;
