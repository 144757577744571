import React, { useState, useEffect } from "react";

// RSS Feeds configuration
const rssFeeds = [
  {
    url: "https://rss.app/feeds/v1.1/ALCPt6TMQk9KmjIj.json",
    label: "Increment Finance",
  },
  {
    url: "https://rss.app/feeds/v1.1/aFMuOBtfU2SOKa0z.json",
    label: "FindLabs",
  },
  {
    url: "https://rss.app/feeds/v1.1/d8IMRa3Q2JZM8TDt.json",
    label: "NLL Council",
  },
  { url: "https://rss.app/feeds/v1.1/VAkUpJG3MpIRQh8U.json", label: "PlayMFL" },
  {
    url: "https://rss.app/feeds/v1.1/cGNj4Te5PcwhSo0K.json",
    label: "Cryptoys",
  },
  { url: "https://rss.app/feeds/v1.1/RHlJ7GRs7Pvsp0Mv.json", label: "Flow" },
  {
    url: "https://rss.app/feeds/v1.1/AQlkR1FPzP9TI3CF.json",
    label: "NFL AllDay",
  },
  {
    url: "https://rss.app/feeds/v1.1/uy5jEXkTODhliL7D.json",
    label: "NBA TopShot",
  },
  {
    url: "https://rss.app/feeds/v1.1/zjgjsbeb3uiEB4Wd.json",
    label: "Diamond Dashboards",
  },
  {
    url: "https://rss.app/feeds/v1.1/Hpqy1dTb1T2R1mr7.json",
    label: "FindLabs 2",
  },
  {
    url: "https://rss.app/feeds/v1.1/x5icoY9XFHcB7GdN.json",
    label: "ABD Collectibles",
  },
  {
    url: "https://rss.app/feeds/v1.1/e3PSqQa9albykuli.json",
    label: "Own The Moment",
  },
  {
    url: "https://rss.app/feeds/v1.1/FUzB7tV75uAXkL2J.json",
    label: "FanCraze",
  },
  {
    url: "https://rss.app/feeds/v1.1/I8ALkonQwO85dyEq.json",
    label: "Collectin&Connectin",
  },
  {
    url: "https://rss.app/feeds/v1.1/DNSgkM9opDKHc00s.json",
    label: "The First Mint",
  },
  {
    url: "https://rss.app/feeds/v1.1/vlBzWhguWJ7AtuvQ.json",
    label: "Flowverse",
  },
];

// Helper function to strip out image tags from the HTML
const stripImagesFromHtml = (htmlContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;

  // Remove all <img> tags from the description content
  const images = tempDiv.getElementsByTagName("img");
  while (images[0]) {
    images[0].parentNode.removeChild(images[0]);
  }

  return tempDiv.innerHTML;
};

const NewsFeed = () => {
  const [feedItems, setFeedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [selectedLabels, setSelectedLabels] = useState([]);

  useEffect(() => {
    const fetchFeeds = async () => {
      let allItems = [];

      try {
        const feedResponses = await Promise.all(
          rssFeeds.map((feed) =>
            fetch(feed.url).then((response) => response.json())
          )
        );

        feedResponses.forEach((data, i) => {
          const labeledItems = data.items.map((item) => ({
            ...item,
            label: rssFeeds[i].label,
            uniqueId: `${rssFeeds[i].label}-${
              item.id || item.url || i
            }-${Math.random()}`,
          }));
          allItems = [...allItems, ...labeledItems];
        });

        allItems.sort(
          (a, b) =>
            new Date(b.date_published || b.pubDate || 0) -
            new Date(a.date_published || a.pubDate || 0)
        );

        setFeedItems(allItems);
      } catch (error) {
        console.error("Error fetching RSS feeds:", error);
      }
    };

    fetchFeeds();
  }, []);

  const filteredItems = selectedLabels.length
    ? feedItems.filter((item) => selectedLabels.includes(item.label))
    : feedItems;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const shortenText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const toggleLabelFilter = (label) => {
    setSelectedLabels((prev) =>
      prev.includes(label) ? prev.filter((l) => l !== label) : [...prev, label]
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Filters */}
      {/* Conditionally hide Filters */}
      {false && ( // Set this to true to show the filters again
        <div className="mb-4">
          <h3 className="font-bold text-lg mb-2">Filter by Source</h3>
          <div className="flex flex-wrap gap-2">
            {rssFeeds.map((feed) => (
              <button
                key={feed.label}
                onClick={() => toggleLabelFilter(feed.label)}
                className={`px-4 py-2 text-sm rounded-md ${
                  selectedLabels.includes(feed.label)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                } hover:bg-blue-400 transition-colors`}
              >
                {feed.label}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* News Feed */}
      {currentItems.length > 0 ? (
        <ul className="space-y-6">
          {currentItems.map((item, index) => (
            <li
              key={item.uniqueId}
              className={`border-b border-gray-300 pb-4 flex flex-col lg:flex-row lg:items-start ${
                index % 2 === 0 ? "lg:flex-row-reverse" : ""
              }`}
            >
              {/* Always show image to the side */}
              {item.image && (
                <a
                  href={item.url || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transition-transform duration-300 hover:scale-105 flex-shrink-0 w-full lg:w-1/2" // Adjust width on non-mobile
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full max-h-64 object-contain" // Ensure image is fully shown without cropping
                    loading="lazy"
                  />
                </a>
              )}

              <div
                className={`lg:w-2/3 ${
                  index % 2 === 0 ? "lg:pr-6" : "lg:pl-6"
                }`}
              >
                <p className="text-sm text-gray-500">{item.label}</p>
                <a
                  href={item.url || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block text-2xl font-semibold text-blue-600 transition-transform duration-300 hover:scale-105 hover:underline"
                >
                  {item.title || "No title available"}
                </a>
                <p className="text-gray-500 text-sm mt-2">
                  {item.date_published
                    ? new Date(item.date_published).toLocaleString()
                    : "No date available"}
                </p>

                {/* Show description (excluding Collectin&Connectin and The First Mint) */}
                {item.label !== "Collectin&Connectin" &&
                  item.label !== "The First Mint" && (
                    <>
                      {item.content_html ? (
                        <div
                          className="mt-3 text-gray-700"
                          dangerouslySetInnerHTML={{
                            __html: shortenText(
                              stripImagesFromHtml(item.content_html),
                              150
                            ),
                          }}
                        />
                      ) : (
                        <p className="mt-3 text-gray-700">
                          {shortenText(
                            item.content_text || "No description available",
                            150
                          )}
                        </p>
                      )}
                    </>
                  )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-center text-gray-500">No news available.</p>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-between mt-6">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`px-4 py-2 bg-green-500 text-white rounded-md transition-transform duration-300 hover:scale-105 hover:bg-green-700 ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 bg-green-500 text-white rounded-md transition-transform duration-300 hover:scale-105 hover:bg-green-700 ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default NewsFeed;
