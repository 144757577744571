import React, { useState, useEffect } from "react";
import { format, addDays } from "date-fns";

const EventCalendar = ({ todayOnly = false }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [today] = useState(new Date());
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [daysToShow, setDaysToShow] = useState(7);

  // Define colors for each event source
  const sourceColors = {
    "Flow Events": "bg-green-500",
    "Flow Community Events": "bg-blue-400",
    "Beta Events": "bg-red-500",
  };

  // Handle screen resize to toggle between mobile and desktop view
  useEffect(() => {
    const handleResize = () => {
      const isMobileScreen = window.innerWidth <= 640;
      setIsMobile(isMobileScreen);
      setDaysToShow(
        todayOnly ? 1 : isMobileScreen ? 1 : Math.floor(window.innerWidth / 250)
      );
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [todayOnly]);

  // Fetch events from the backend APIs
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const flowEventsResponse = await fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/flow-events"
        );
        const flowCommunityEventsResponse = await fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/flow-community-events"
        );
        const betaEventsResponse = await fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/beta-events"
        );

        if (
          !flowEventsResponse.ok ||
          !flowCommunityEventsResponse.ok ||
          !betaEventsResponse.ok
        ) {
          throw new Error("Failed to fetch events");
        }

        const flowEventsData = await flowEventsResponse.json();
        const flowCommunityEventsData =
          await flowCommunityEventsResponse.json();
        const betaEventsData = await betaEventsResponse.json();

        const combinedEvents = [
          ...flowEventsData.map((event) => ({
            ...event,
            source: "Flow Events",
            start: event.start?.dateTime || event.start?.date,
            end: event.end?.dateTime || event.end?.date || null,
            isAllDay: event.start?.date ? true : false,
          })),
          ...flowCommunityEventsData.map((event) => ({
            ...event,
            source: "Flow Community Events",
            start: event.start?.dateTime || event.start?.date,
            end: event.end?.dateTime || event.end?.date || null,
            isAllDay: event.start?.date ? true : false,
          })),
          ...betaEventsData.map((event) => ({
            ...event,
            source: "Beta Events",
            start: event.start?.dateTime || event.start?.date,
            end: event.end?.dateTime || event.end?.date || null,
            isAllDay: event.start?.date ? true : false,
          })),
        ];

        const validEvents = combinedEvents.filter(
          (event) => event.start && !isNaN(new Date(event.start))
        );

        const sortedEvents = validEvents
          .map((event) => ({
            ...event,
            id: event.id,
            title: event.summary || "No Title",
            start: new Date(event.start),
            end: event.end ? new Date(event.end) : null,
            description: event.description || "No description",
            location: event.location || "Google Meet",
            htmlLink: event.htmlLink || "",
            source: event.source,
            isAllDay: event.isAllDay,
          }))
          .sort((a, b) => new Date(a.start) - new Date(b.start));

        setEvents(sortedEvents);
        setLoading(false);
      } catch (err) {
        setError("Failed to load events");
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const getEventsForDate = (date) => {
    return events
      .filter(
        (event) =>
          format(event.start, "yyyy-MM-dd") === format(date, "yyyy-MM-dd")
      )
      .sort((a, b) => new Date(a.start) - new Date(b.start));
  };

  const formatTime = (time, isAllDay) => {
    if (isAllDay) return "All-day";
    const date = new Date(time);
    return format(date, "p");
  };

  const handleNextDay = () => {
    setCurrentDayIndex((prevIndex) => prevIndex + daysToShow);
  };

  const handlePrevDay = () => {
    setCurrentDayIndex((prevIndex) => Math.max(0, prevIndex - daysToShow));
  };

  const daysToRender = todayOnly
    ? [today]
    : [...Array(daysToShow)].map((_, index) =>
        addDays(today, index + currentDayIndex)
      );

  if (loading) {
    return <div>Loading events...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {!todayOnly && (
        <div className="flex justify-between items-center mb-4">
          {currentDayIndex > 0 && (
            <button
              onClick={handlePrevDay}
              className="bg-indigo-600 text-white px-4 py-2 rounded hover:scale-105 transition-transform duration-300"
            >
              ←
            </button>
          )}
          <button
            onClick={handleNextDay}
            className="bg-indigo-600 text-white px-4 py-2 rounded hover:scale-105 transition-transform duration-300"
          >
            →
          </button>
        </div>
      )}

      <div className="flex overflow-x-auto overflow-y-hidden space-x-4 no-scrollbar">
        {daysToRender.map((day, index) => {
          const dayEvents = getEventsForDate(day);
          return (
            <div
              key={index}
              className={`${
                todayOnly || isMobile ? "w-full" : "min-w-[250px] w-[250px]"
              } p-4 bg-gray-200 rounded-lg shadow-md flex-shrink-0`}
            >
              <h2 className="text-lg font-semibold">
                {format(day, "EEEE, MMM dd")}
              </h2>
              {dayEvents.length > 0 ? (
                dayEvents.map((event) => (
                  <div
                    key={event.id}
                    className="bg-white p-2 mt-2 rounded-lg shadow"
                  >
                    <span
                      className={`text-white px-2 py-1 rounded text-sm inline-block ${
                        sourceColors[event.source]
                      }`}
                    >
                      {event.source}
                    </span>
                    <p className="font-bold">
                      {event.isAllDay ? "All-day" : formatTime(event.start)}
                    </p>
                    <p
                      className="font-bold cursor-pointer text-blue-600 underline hover:scale-105 transition-transform duration-300"
                      onClick={() => setSelectedEvent(event)}
                    >
                      {event.title}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-500">No events today</p>
              )}
            </div>
          );
        })}
      </div>

      {/* Modal for event details */}
      {selectedEvent && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          onClick={() => setSelectedEvent(null)}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full break-words hover:scale-105 transition-transform duration-300"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-bold mb-2">{selectedEvent.title}</h2>
            <p className="mb-2">
              <strong>Time:</strong>{" "}
              {selectedEvent.isAllDay
                ? "All-day"
                : `${formatTime(selectedEvent.start)} - ${
                    selectedEvent.end
                      ? formatTime(selectedEvent.end)
                      : "No end time"
                  }`}
            </p>
            <p className="mb-2">
              <strong>Description:</strong>{" "}
              {selectedEvent.description || "No description"}
            </p>
            <p className="mb-2 break-words">
              <strong>Location:</strong>{" "}
              {selectedEvent.location || "Google Meet"}
            </p>
            {selectedEvent.htmlLink && (
              <a
                href={selectedEvent.htmlLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline block mb-4 break-words hover:scale-105 transition-transform duration-300"
              >
                View in Google Calendar
              </a>
            )}
            <div className="text-center">
              <button
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:scale-105 transition-transform duration-300"
                onClick={() => setSelectedEvent(null)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventCalendar;
