import React, { useEffect, useState, useCallback } from "react";
import PinnacleLogo from "../logos/Pinnacle.png"; // Import the logo
import { parseUnicode } from "./Utilities"; // Import parseUnicode function
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import * as fcl from "@onflow/fcl"; // Import Flow FCL for user login
import { FaInstagram, FaTwitter, FaDiscord, FaReddit } from "react-icons/fa";

// Function to determine background color based on edition type
const getSetBackground = (editionType) => {
  switch (editionType) {
    case "Genesis Edition":
      return "bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 animate-slow-wave";
    case "Starter Edition":
      return "bg-indigo-400";
    case "Open Edition":
      return "bg-blue-300";
    case "Limited Edition":
      return "bg-purple-800";
    case "Limited Event Edition":
      return "bg-cyan-500";
    case "Open Event Edition":
      return "bg-cyan-300";
    default:
      return "bg-gray-100";
  }
};

// Function to split the set name into studio and actual set name
const splitSetName = (setName) => {
  if (!setName) return { studio: "Unknown Studio", setName: "Unknown Set" };
  const [studio, set] = setName.split(" • ");
  return {
    studio: studio || "Unknown Studio",
    setName: set || "Unknown Set",
  };
};

// Function to get the set image, with a fallback to a placeholder
const getSetImage = (setID) => {
  const imageUrl = `https://storage.googleapis.com/flowconnect/pinnacle/sets/${setID}.png`;

  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve(imageUrl); // If the image loads, return the set image
    img.onerror = () =>
      resolve(
        "https://storage.googleapis.com/flowconnect/pinnacle/sets/placeholderset.png"
      ); // If it fails, return the placeholder
  });
};

const PinnacleDiscovery = () => {
  const [user, setUser] = useState(null); // State for storing the logged-in user
  const [nftCollection, setNftCollection] = useState({}); // Store NFTs by editionID
  const [editionData, setEditionData] = useState([]); // External edition data
  const [setData, setSetData] = useState([]); // Set data
  const [series, setSeries] = useState([]); // Series data
  const [selectedSeries, setSelectedSeries] = useState(null); // Selected series
  const [completedSetIDs, setCompletedSetIDs] = useState([]); // Store completed sets
  const [loading, setLoading] = useState(false); // Handle loading
  const [summary, setSummary] = useState({ seriesSummary: {}, totalSets: 0 }); // Track summary by series and set type
  const [isAboutVisible, setIsAboutVisible] = useState(false); // Toggle About section
  const [setImages, setSetImages] = useState({}); // Store images for each set

  // Subscribing to the current logged-in user
  useEffect(() => {
    fcl.currentUser().subscribe((user) => {
      setUser(user?.addr ? user : null);
      if (!user?.addr) {
        setCompletedSetIDs([]); // Clear completed sets when logged out
        setNftCollection({});
      }
    });
  }, []);

  // Fetch sets and series data
  const fetchData = async () => {
    setLoading(true);
    try {
      const [seriesRes, setsRes] = await Promise.all([
        fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-series"
        ).then((res) => res.json()),
        fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-sets"
        ).then((res) => res.json()),
      ]);

      setSeries(seriesRes || []);
      setSetData(setsRes || []);

      if (seriesRes?.length > 0) {
        setSelectedSeries(seriesRes[0].id);
      }

      // Fetch images for each set
      const imagePromises = setsRes.map((set) =>
        getSetImage(set.id).then((imageUrl) => ({ setID: set.id, imageUrl }))
      );
      const imageResults = await Promise.all(imagePromises);
      const imageMap = imageResults.reduce((acc, img) => {
        acc[img.setID] = img.imageUrl;
        return acc;
      }, {});
      setSetImages(imageMap);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(); // Fetch sets and series on mount
  }, []);

  // Fetch user NFT data if user is logged in
  useEffect(() => {
    if (user?.addr) {
      fetchUserNFTs(user.addr);
    }
  }, [user]);

  // Fetch user NFT collection and calculate completed sets
  const fetchUserNFTs = async (userAddress) => {
    if (!userAddress) return;

    try {
      setLoading(true);
      const nftResult = await fcl.query({
        cadence: `import NonFungibleToken from 0x1d7e57aa55817448
                  import Pinnacle from 0xedf9df96c92f4595
                  import HybridCustody from 0xd8a7e05a7ac670c0

                  access(all) struct NFTDetails {
                    access(all) let id: UInt64
                    access(all) let editionID: Int
                    access(all) let serialNumber: UInt64?

                    init(id: UInt64, editionID: Int, serialNumber: UInt64?) {
                        self.id = id
                        self.editionID = editionID
                        self.serialNumber = serialNumber
                    }
                  }

                  access(all) fun getNFTsFromAddress(address: Address): [NFTDetails] {
                    let account = getAccount(address)

                    let collectionRef = account
                        .capabilities
                        .borrow<&Pinnacle.Collection>(/public/PinnacleCollection)

                    if collectionRef == nil {
                        return []
                    }

                    let nftIDs = collectionRef!.getIDs()
                    var nftDetailsList: [NFTDetails] = []

                    for id in nftIDs {
                        let nftRef = collectionRef!.borrowPinNFT(id: id)
                            ?? panic("Could not borrow a reference to the NFT")

                        let nftDetails = NFTDetails(
                            id: nftRef.id,
                            editionID: nftRef.editionID,
                            serialNumber: nftRef.serialNumber
                        )

                        nftDetailsList.append(nftDetails)
                    }

                    return nftDetailsList
                  }

                  access(all) fun main(address: Address): [NFTDetails] {
                    var allNftDetails: [NFTDetails] = []

                    let parentNFTs = getNFTsFromAddress(address: address)
                    allNftDetails.appendAll(parentNFTs)

                    let acct = getAuthAccount<auth(Storage) &Account>(address)
                    let manager = 
                        acct.storage.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                        ?? panic("Manager not found")

                    let childAddresses = manager.getChildAddresses()

                    for childAddress in childAddresses {
                        let childNFTs = getNFTsFromAddress(address: childAddress)
                        allNftDetails.appendAll(childNFTs)
                    }

                    return allNftDetails
                  }
        `,
        args: (arg, t) => [arg(userAddress, t.Address)],
      });

      const editionsRes = await fetch(
        "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-editions"
      ).then((res) => res.json());

      const groupedNFTs = groupByEditionID(nftResult || []);
      const completedSetIDs = calculateCompletedSets(groupedNFTs, editionsRes);
      const summary = calculateSummary(groupedNFTs, editionsRes);

      setNftCollection(groupedNFTs);
      setEditionData(editionsRes);
      setCompletedSetIDs(completedSetIDs);
      setSummary(summary); // Update the summary
    } catch (error) {
      console.error("Error fetching user's NFTs:", error);
    }
    setLoading(false);
  };

  // Group NFTs by editionID
  const groupByEditionID = useCallback((nfts) => {
    const grouped = {};
    nfts.forEach((nft) => {
      if (!grouped[nft.editionID]) {
        grouped[nft.editionID] = [];
      }
      grouped[nft.editionID].push(nft);
    });
    return grouped;
  }, []);

  // Calculate completed sets based on owned editions
  const calculateCompletedSets = (nftGrouped, editions) => {
    const completedSetIDs = new Set();

    // Map setID to its required editionIDs
    const setsToEditionIDs = editions.reduce((acc, edition) => {
      if (!acc[edition.setID]) {
        acc[edition.setID] = [];
      }
      acc[edition.setID].push(edition.id); // Push editionID to the set
      return acc;
    }, {});

    // Check if user owns all editionIDs for each setID
    Object.keys(setsToEditionIDs).forEach((setID) => {
      const requiredEditionIDs = setsToEditionIDs[setID];
      const ownedAllEditions = requiredEditionIDs.every(
        (editionID) => nftGrouped[editionID]?.length > 0
      );
      if (ownedAllEditions) {
        completedSetIDs.add(parseInt(setID)); // Mark the set as completed
      }
    });

    return Array.from(completedSetIDs);
  };

  // Calculate summary for completed sets (no editionType breakdown)
  const calculateSummary = (nftGrouped, editions) => {
    const summary = {
      seriesSummary: {}, // Summary by series
      totalSets: 0, // Total sets completed
    };

    const setsToEditionIDs = editions.reduce((acc, edition) => {
      if (!acc[edition.setID]) {
        acc[edition.setID] = [];
      }
      acc[edition.setID].push(edition.id); // Push editionID to the set
      return acc;
    }, {});

    Object.keys(setsToEditionIDs).forEach((setID) => {
      const requiredEditionIDs = setsToEditionIDs[setID];
      const ownedAllEditions = requiredEditionIDs.every(
        (editionID) => nftGrouped[editionID]?.length > 0
      );

      if (ownedAllEditions) {
        const seriesID = editions.find(
          (edition) => edition.setID === parseInt(setID)
        )?.seriesID;
        if (!summary.seriesSummary[seriesID]) {
          summary.seriesSummary[seriesID] = 0;
        }
        summary.seriesSummary[seriesID]++;
        summary.totalSets++;
      }
    });

    return summary;
  };

  // Filtered sets based on selected series
  const filteredSets = selectedSeries
    ? setData.filter((set) => set.seriesID === selectedSeries)
    : [];

  return (
    <div className="p-2">
      {/* Banner with logo and social links */}
      <div className="flex flex-col lg:flex-row items-center bg-gradient-to-r from-purple-600 via-blue-600 to-pink-600 p-6 rounded-lg shadow-lg">
        {/* Logo on the left */}
        <a
          href="https://disneypinnacle.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="lg:flex-shrink-0 hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
        >
          <img src={PinnacleLogo} alt="Disney Pinnacle Logo" className="w-64" />
        </a>

        {/* Social links to the right of the logo (stacked below on mobile) */}
        <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mt-4 lg:mt-0 lg:ml-8">
          <a
            href="https://www.instagram.com/disneypinnacle/"
            className="text-white hover:text-purple-200 transition duration-300 flex items-center hover:scale-105 transition-transform duration-300"
            aria-label="Instagram"
          >
            <FaInstagram className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Instagram</span>
          </a>
          <a
            href="https://x.com/DisneyPinnacle"
            className="text-white hover:text-purple-200 transition duration-300 flex items-center hover:scale-105 transition-transform duration-300"
            aria-label="X"
          >
            <FaTwitter className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">X</span>
          </a>
          <a
            href="https://discord.gg/disneypinnacle"
            className="text-white hover:text-purple-200 transition duration-300 flex items-center hover:scale-105 transition-transform duration-300"
            aria-label="Discord"
          >
            <FaDiscord className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Discord</span>
          </a>
          <a
            href="https://www.reddit.com/r/disneypinnacle/"
            className="text-white hover:text-purple-200 transition duration-300 flex items-center hover:scale-105 transition-transform duration-300"
            aria-label="Reddit"
          >
            <FaReddit className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">/r/DisneyPinnacle</span>
          </a>
          <a
            href="https://www.reddit.com/r/disneypins"
            className="text-white hover:text-purple-200 transition duration-300 flex items-center hover:scale-105 transition-transform duration-300"
            aria-label="Reddit"
          >
            <FaReddit className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">/r/DisneyPins</span>
          </a>
        </div>
      </div>

      {/* About section toggle button */}
      <div className="mt-6">
        <button
          onClick={() => setIsAboutVisible(!isAboutVisible)}
          className="mb-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:scale-105 transition-transform duration-300"
        >
          {isAboutVisible ? "Hide About" : "About"}
        </button>

        {/* Conditionally render the about section */}
        {isAboutVisible && (
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-gray-700 mb-4">
              Chase and collect digital pins featuring characters you love, from
              Disney, Pixar and STAR WARS™
            </p>
            <p className="text-gray-700 mb-4">
              This is a non-commercial, community-driven project created to
              celebrate and share the unique digital collectible offerings
              available through the{" "}
              <a
                href="https://disneypinnacle.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
                onClick={() =>
                  trackEventClick("External Link", "Disney Pinnacle Site")
                }
              >
                Disney Pinnacle™ platform
              </a>
              .
            </p>
            <p className="text-gray-700 mb-4">
              We strive to respect and adhere to all copyright and licensing
              terms.
            </p>
            <p className="text-gray-700 mb-4">
              Special thanks to{" "}
              <a
                href="https://linktr.ee/diamondflow"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
                onClick={() => trackEventClick("External Link", "Diamond")}
              >
                Diamond
              </a>{" "}
              and{" "}
              <a
                href="https://x.com/RyanKSterling"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
                onClick={() =>
                  trackEventClick("External Link", "Ryan Sterling")
                }
              >
                Ryan Sterling
              </a>{" "}
              for their development help and support.
            </p>
            <p className="text-gray-700">
              Join the discussion and provide feedback on our{" "}
              <a
                href="https://discord.gg/5MU4ngQQ"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
                onClick={() => trackEventClick("External Link", "Discord")}
              >
                Discord
              </a>
              .
            </p>
          </div>
        )}
      </div>

      {/* Summary display for logged-in users */}
      {user && (
        <div className="bg-gray-200 p-4 rounded-lg mb-5">
          <h3 className="text-xl font-bold mb-3">Your Set Progress</h3>
          {Object.keys(summary.seriesSummary).map((seriesID) => (
            <div key={seriesID} className="mb-2">
              <p className="font-bold">Series {seriesID}</p>
              <p>
                Sets Completed: {summary.seriesSummary[seriesID]} /{" "}
                {
                  setData.filter((set) => set.seriesID === parseInt(seriesID))
                    .length
                }
              </p>
            </div>
          ))}
          <p>Total sets completed: {summary.totalSets}</p>
        </div>
      )}

      {/* Message to log in if the user is not logged in */}
      {!user && (
        <div className="text-center mb-4">
          <p className="text-sm text-gray-600">
            Log in to track your progress and see completed sets.
          </p>
        </div>
      )}

      {/* Series tabs */}
      <div className="flex space-x-2 mb-4">
        {series.map((serie) => (
          <button
            key={serie.id}
            onClick={() => setSelectedSeries(serie.id)}
            className={`px-4 py-2 rounded-lg border transition-transform duration-300 hover:scale-105 ${
              selectedSeries === serie.id
                ? "bg-green-500 text-white"
                : "bg-white border-green-500 text-black"
            }`}
          >
            {`Series ${serie.name}`}
          </button>
        ))}
      </div>

      {/* Sets view */}
      {selectedSeries && filteredSets.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {filteredSets.map((set) => {
            const { studio, setName } = splitSetName(parseUnicode(set.name));

            // Check if the user has completed this set
            const isCompleted = completedSetIDs.includes(set.id);
            const setImage = setImages[set.id] || "/public/placeholderset.png";

            return (
              <Link
                to={{
                  pathname: `/pinnacle/sets/${set.id}`, // Navigate to PinnacleSets page
                  state: {
                    nftCollection, // Pass the user's NFT collection as a prop
                    completedSetIDs, // Pass completed sets as a prop
                    setData, // Pass setData if needed
                    editionData, // Pass editionData if needed
                  },
                }}
                key={set.id}
                className="no-underline"
              >
                <div
                  className={`${getSetBackground(
                    set.editionType
                  )} text-slate-200 p-4 rounded-lg shadow-md flex flex-col justify-between transition-transform duration-300 hover:scale-105 ${
                    isCompleted
                      ? "border-8 border-green-500 rounded-lg relative"
                      : ""
                  }`} // Apply solid green border if completed
                  style={{ height: "350px" }} // Fixed height for uniformity
                >
                  {isCompleted && (
                    <div className="absolute inset-0 border-4 border-green-500 rounded-lg"></div>
                  )}
                  <img
                    src={setImage}
                    alt={`${setName} Image`}
                    className="w-auto h-48 mx-auto mb-4 object-cover rounded-lg"
                  />
                  <p className="text-center font-bold">{setName}</p>
                  <p className="text-center text-sm">{studio}</p>
                  <p className="text-center text-sm">{set.editionType}</p>
                </div>
              </Link>
            );
          })}
        </div>
      )}

      {/* Footer with disclaimer */}
      <footer className="mt-8">
        <p className="text-sm text-gray-600">
          © Dapper Labs, Inc. | © Disney | © Disney/Pixar | © & ™ LUCASFILM LTD.
          All rights reserved.
          <br />
          App Store is a service mark of Apple Inc. | Google Play is a trademark
          of Google Inc.
        </p>
        <p className="text-sm text-gray-600 mt-2">
          Please note that the images of NFT assets showcased here are for
          personal, non-commercial use only, in accordance with the Terms of Use
          for Disney Pinnacle™. Any modification, commercial use, or
          unauthorized distribution is strictly prohibited.
        </p>
      </footer>
    </div>
  );
};

export default PinnacleDiscovery;
