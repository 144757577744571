import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import editionNames from "../data/editionNames.json"; // Import edition names
import { parseUnicode } from "./Utilities";
import { UserContext } from "./UserContext";

// Function to dynamically return edition images from the public folder
const getEditionImage = (id) => {
  return new Promise((resolve) => {
    // Define file paths for both lowercase and uppercase extensions
    const pngImage = `https://storage.googleapis.com/flowconnect/pinnacle/pins/${id}.png`;
    const pngImageUppercase = `https://storage.googleapis.com/flowconnect/pinnacle/pins/${id}.PNG`;
    const webpImage = `https://storage.googleapis.com/flowconnect/pinnacle/pins/${id}.webp`;
    const webpImageUppercase = `https://storage.googleapis.com/flowconnect/pinnacle/pins/${id}.WEBP`;
    const gifImage = `https://storage.googleapis.com/flowconnect/pinnacle/pins/${id}.gif`;

    // Try WEBP first (both lowercase and uppercase)
    const webpImg = new Image();
    webpImg.src = webpImage;

    webpImg.onload = () => {
      resolve(webpImage); // Resolve if lowercase webp loads
    };
    webpImg.onerror = () => {
      // Check if uppercase WEBP exists
      const webpImgUppercase = new Image();
      webpImgUppercase.src = webpImageUppercase;

      webpImgUppercase.onload = () => {
        resolve(webpImageUppercase); // Resolve if uppercase WEBP loads
      };
      webpImgUppercase.onerror = () => {
        // If WEBP fails, try PNG (both lowercase and uppercase)
        const pngImg = new Image();
        pngImg.src = pngImage;

        pngImg.onload = () => {
          resolve(pngImage); // Resolve if lowercase PNG loads
        };
        pngImg.onerror = () => {
          const pngImgUppercase = new Image();
          pngImgUppercase.src = pngImageUppercase;

          pngImgUppercase.onload = () => {
            resolve(pngImageUppercase); // Resolve if uppercase PNG loads
          };
          pngImgUppercase.onerror = () => {
            // If PNG fails, try GIF
            const gifImg = new Image();
            gifImg.src = gifImage;

            gifImg.onload = () => {
              resolve(gifImage); // Resolve GIF if it loads
            };
            gifImg.onerror = () => {
              // If all fail, resolve with placeholder
              resolve("/images/placeholder.png");
            };
          };
        };
      };
    };
  });
};

// Function to determine background color based on edition type
const getSetBackground = (editionType) => {
  switch (editionType) {
    case 1:
      return "bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 animate-slow-wave"; //good
    case 2:
      return "bg-blue-300";
    case 3:
      return "bg-purple-800"; //good
    case 4:
      return "bg-blue-300"; //good
    case 5:
      return "bg-indigo-400"; //good
    case 7:
      return "bg-cyan-500"; //good
    default:
      return "bg-gray-100";
  }
};

// Function to format the date
const formatDate = (timestamp) => {
  if (!timestamp) return "N/A";
  const date = new Date(timestamp * 1000); // Convert UNIX timestamp to JavaScript Date
  return date.toLocaleDateString(); // Format as mm/dd/yyyy
};

// Function to handle pagination buttons and logic
const getPagination = (totalPages, currentPage) => {
  const delta = 2; // Show current page +/- 2
  const range = [];

  // Pages to display always (first, last, current, around current)
  for (
    let i = Math.max(2, currentPage - delta);
    i <= Math.min(totalPages - 1, currentPage + delta);
    i++
  ) {
    range.push(i);
  }

  // Handle pagination dots
  if (currentPage - delta > 2) {
    range.unshift("...");
  }
  if (currentPage + delta < totalPages - 1) {
    range.push("...");
  }

  range.unshift(1); // Always show the first page
  if (totalPages > 1) {
    range.push(totalPages); // Always show the last page if applicable
  }

  return range;
};

const PinnacleEdition = () => {
  const { editionId } = useParams();
  const navigate = useNavigate();
  const [editionData, setEditionData] = useState(null);
  const [owners, setOwners] = useState([]); // State for owners
  const [error, setError] = useState(null);
  const [editionImage, setEditionImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ownersPerPage = 10; // Define how many owners to display per page
  const [vaultData, setVaultData] = useState({
    CapsuleVault: {},
    PromoVault: {},
    PinnacleVault: {},
  });
  const [burnData, setBurnData] = useState({});
  const { user } = useContext(UserContext);

  // Function to determine the correct API endpoint based on the editionId
  const getApiEndpoint = (editionId) => {
    if (editionId >= 1 && editionId <= 100) {
      return `https://flipsidecrypto.xyz/api/v1/queries/c3e302cd-e938-430d-ba2c-a5d99eaf9a1d/data/latest`;
    } else if (editionId >= 101 && editionId <= 200) {
      return `https://flipsidecrypto.xyz/api/v1/queries/a9fc3be5-740f-4115-aac7-30a50128b096/data/latest`;
    } else if (editionId >= 201 && editionId <= 300) {
      return `https://flipsidecrypto.xyz/api/v1/queries/9389cdb8-fe7f-4b07-8aa3-6e4cd803b0a5/data/latest`;
    } else if (editionId >= 301 && editionId <= 400) {
      return `https://flipsidecrypto.xyz/api/v1/queries/135dea42-350b-431a-9952-298c87ea8572/data/latest`;
    } else if (editionId >= 401 && editionId <= 500) {
      return `https://flipsidecrypto.xyz/api/v1/queries/4234eb7f-da76-4220-83d3-d610654c97b9/data/latest`;
    } else if (editionId >= 501 && editionId <= 600) {
      return `https://flipsidecrypto.xyz/api/v1/queries/ccb38128-075b-48b5-b633-5de00e8eef03/data/latest`;
    }
    return null;
  };

  useEffect(() => {
    const fetchEditionData = async () => {
      try {
        const response = await fetch(
          `https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-editions`
        );
        const editions = await response.json();
        const currentEdition = editions.find(
          (edition) => edition.id === Number(editionId)
        );

        if (!currentEdition)
          throw new Error(`Edition with ID ${editionId} not found`);

        setEditionData(currentEdition);

        const imageSrc = await getEditionImage(editionId);
        setEditionImage(imageSrc);
      } catch (error) {
        setError(error.message);
      }
    };

    const fetchOwnersData = async () => {
      try {
        const apiEndpoint = getApiEndpoint(Number(editionId));
        if (!apiEndpoint) {
          throw new Error("Invalid edition ID or no data available");
        }

        const response = await fetch(apiEndpoint);
        const data = await response.json();

        // Convert both editionId and owner.EDITIONID to strings for comparison
        let filteredOwners = data.filter(
          (owner) => String(owner.EDITIONID) === String(editionId)
        );

        // Sort by quantity and take the top 500
        filteredOwners = filteredOwners
          .sort((a, b) => b.QUANTITY - a.QUANTITY)
          .slice(0, 500);

        setOwners(filteredOwners);
      } catch (error) {
        console.error("Failed to fetch owners data:", error);
      }
    };

    const fetchVaultData = async () => {
      try {
        const response = await fetch(
          `https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-vaults`
        );
        const vaultData = await response.json();
        const vaultDataMap = vaultData.reduce(
          (acc, vault) => {
            if (vault.vault_address === "0xb6f2481eba4df97b") {
              acc.CapsuleVault[vault.editionID] = vault.quantity;
            } else if (vault.vault_address === "0xd708cc29ee72b8ee") {
              acc.PromoVault[vault.editionID] = vault.quantity;
            }
            return acc;
          },
          { CapsuleVault: {}, PromoVault: {}, PinnacleVault: {} }
        );

        setVaultData(vaultDataMap);
      } catch (error) {
        console.error("Failed to fetch vault data:", error);
      }
    };

    const fetchPinnacleVaultData = async () => {
      try {
        const response = await fetch(
          `https://flipsidecrypto.xyz/api/v1/queries/83a51bfc-3d5c-408e-85c4-0bf0a0109544/data/latest`
        );
        const pinnacleVaultData = await response.json();

        const pinnacleVaultMap = pinnacleVaultData.reduce((acc, vault) => {
          if (vault.VAULT_ADDRESS === "0xedf9df96c92f4595") {
            acc[vault.EDITIONID] = vault.QUANTITY;
          }
          return acc;
        }, {});

        setVaultData((prevVaultData) => ({
          ...prevVaultData,
          PinnacleVault: pinnacleVaultMap,
        }));
      } catch (error) {
        console.error("Failed to fetch PinnacleVault data:", error);
      }
    };

    const fetchBurnData = async () => {
      try {
        const response = await fetch(
          `https://flipsidecrypto.xyz/api/v1/queries/cdb2b538-ad5f-46a4-957d-c17f52e044fa/data/latest`
        );
        const burnData = await response.json();
        const burnDataMap = burnData.reduce((acc, burn) => {
          acc[burn.EDITIONID] = burn.BURN_COUNT;
          return acc;
        }, {});

        setBurnData(burnDataMap);
      } catch (error) {
        console.error("Failed to fetch burn data:", error);
      }
    };

    fetchEditionData();
    fetchOwnersData();
    fetchVaultData();
    fetchPinnacleVaultData(); // Fetch PinnacleVault data
    fetchBurnData();
  }, [editionId]);

  if (error) {
    return <div className="p-8 text-red-500">Error: {error}</div>;
  }

  if (!editionData) {
    return <div>Loading...</div>;
  }

  // Pagination logic for owners
  const totalPages = Math.ceil(owners.length / ownersPerPage);
  const indexOfLastOwner = currentPage * ownersPerPage;
  const indexOfFirstOwner = indexOfLastOwner - ownersPerPage;
  const currentOwners = owners.slice(indexOfFirstOwner, indexOfLastOwner);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Get pagination range using getPagination function
  const paginationButtons = getPagination(totalPages, currentPage);

  // Function to calculate circulated pins
  const calculateCirculated = (
    minted,
    capsuleVault,
    promoVault,
    burn,
    pinnacleVault
  ) => {
    return minted - capsuleVault - promoVault - burn - pinnacleVault;
  };

  // Get the name from editionNames.json
  const editionName = editionNames[editionId] || "Unknown Edition";

  return (
    <div className="p-8">
      {/* Back Button */}
      <button
        className="mb-4 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
        onClick={() => navigate(`/pinnacle/sets/${editionData.setID}`)} // Navigate to the set associated with the edition
      >
        ← Back
      </button>

      {/* Display the parsed edition name from editionNames.json */}
      <h1 className="text-3xl font-bold mb-4">{parseUnicode(editionName)}</h1>

      <div
        className={`relative border px-4 py-2 rounded-lg shadow-md text-left flex flex-col justify-between ${getSetBackground(
          editionData.editionTypeID
        )} text-slate-200`}
      >
        <div>
          {/* Edition Image */}
          <img
            src={editionImage || "/images/placeholder.png"}
            alt={`Edition ${editionId}`}
            className="w-auto h-48 mx-auto mb-4 object-cover"
          />

          <h3 className="text-lg font-bold text-center">
            {editionName}{" "}
            {editionData.isChaser && (
              <span className="text-yellow-500"> ★</span>
            )}
          </h3>

          <p className="text-sm mt-2">
            <strong>Edition ID:</strong> {editionData.id} &nbsp;
            <strong>Variant:</strong> {editionData.variant}
          </p>

          <div className="text-sm">
            {editionData.traits?.Materials?.length > 0 && (
              <p>
                <strong>Materials:</strong>{" "}
                {editionData.traits.Materials.join(", ")}
              </p>
            )}
            {editionData.traits?.Effects?.length > 0 && (
              <p>
                <strong>Effects:</strong>{" "}
                {editionData.traits.Effects.join(", ")}
              </p>
            )}
          </div>

          {/* Creation and Closed Dates */}
          <p className="text-sm mt-2">
            <strong>Creation Date:</strong>{" "}
            {formatDate(editionData.creationDate)}
          </p>
          {editionData.closedDate && (
            <p className="text-sm">
              <strong>Closed Date:</strong> {formatDate(editionData.closedDate)}
            </p>
          )}

          {/* Edition Description */}
          <p className="text-sm mt-4">{editionData.description}</p>

          {/* Table with minting, burn, and circulation data */}
          <table className="w-full mt-4 text-left text-sm">
            <thead>
              <tr>
                <th>Category</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody className="text-black">
              <tr className="bg-green-100">
                <td>Minted</td>
                <td>{editionData.numberMinted || 0}</td>
              </tr>
              <tr className="bg-yellow-100">
                <td>CapsuleVault</td>
                <td>{vaultData.CapsuleVault[editionId] || 0}</td>
              </tr>
              <tr className="bg-purple-100">
                <td>PromoVault</td>
                <td>{vaultData.PromoVault[editionId] || 0}</td>
              </tr>
              <tr className="bg-blue-100">
                <td>PinnacleVault</td>
                <td>{vaultData.PinnacleVault[editionId] || 0}</td>
              </tr>
              <tr className="bg-red-100">
                <td>Burned</td>
                <td>{burnData[editionId] || 0}</td>
              </tr>
              <tr className="bg-gray-100">
                <td>Circulated</td>
                <td>
                  {calculateCirculated(
                    editionData.numberMinted || 0,
                    vaultData.CapsuleVault[editionId] || 0,
                    vaultData.PromoVault[editionId] || 0,
                    burnData[editionId] || 0,
                    vaultData.PinnacleVault[editionId] || 0
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Owners Table */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">
          Top 500 Owners of this Edition
        </h2>

        <table className="w-full text-left text-sm">
          <thead>
            <tr>
              <th className="border px-4 py-2">Owner Wallet</th>
              <th className="border px-4 py-2">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {currentOwners.length > 0 ? (
              currentOwners.map((owner, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="border px-4 py-2">{owner.OWNER_WALLET}</td>
                  <td className="border px-4 py-2">{owner.QUANTITY}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="border px-4 py-2 text-center" colSpan="2">
                  No owners found for this edition.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Smarter Pagination Controls */}
        <div className="mt-4 flex justify-center space-x-2">
          <button
            onClick={handlePrev}
            disabled={currentPage === 1}
            className={`px-3 py-1 rounded ${
              currentPage === 1
                ? "bg-gray-200 text-gray-400"
                : "bg-gray-300 text-gray-800 hover:bg-gray-400"
            }`}
          >
            Prev
          </button>

          {paginationButtons.map((page, index) => (
            <button
              key={index}
              onClick={() => typeof page === "number" && paginate(page)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === page
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              disabled={typeof page !== "number"}
            >
              {page}
            </button>
          ))}

          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 rounded ${
              currentPage === totalPages
                ? "bg-gray-200 text-gray-400"
                : "bg-gray-300 text-gray-800 hover:bg-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PinnacleEdition;
