import React from "react";
import FlowLogo from "../logos/flow.svg";
import OpenAILogo from "../logos/openai.svg";
import XLogo from "../logos/x.svg"; // Importing the X logo
import DiscordLogo from "../logos/discord.svg"; // Importing the Discord logo

function Footer() {
  return (
    <footer className="bg-gray-400 text-white relative bottom-0 left-0 w-full">
      <div className="flex items-center justify-center py-4">
        {/* Follow us on X */}
        <div className="flex items-center px-4">
          <span className="text-sm text-white font-inter">Follow us on:</span>
          <a
            href="https://x.com/FlowConnect_io"
            aria-label="X logo"
            className="ml-2"
          >
            <img
              src={XLogo}
              alt="X logo"
              className="h-6 hover:scale-105 transition-transform duration-300"
            />
          </a>
        </div>

        {/* Join us on Discord */}
        <div className="flex items-center px-4">
          <span className="text-sm text-white font-inter">Join us on:</span>
          <a
            href="https://discord.gg/AwaNfedhgK"
            aria-label="Discord logo"
            className="ml-2"
          >
            <img
              src={DiscordLogo}
              alt="Discord logo"
              className="h-6 hover:scale-105 transition-transform duration-300"
            />
          </a>
        </div>

        {/* Flow Logo */}
        <div className="flex items-center px-4 text-white font-inter">
          <a
            href="https://www.flow.com/"
            aria-label="Flow logo"
            className="flex items-center"
          >
            <img
              src={FlowLogo}
              alt="Flow logo"
              className="h-16 mr-2 hover:scale-105 transition-transform duration-300"
            />
          </a>
          <div className="flex flex-col items-center text-white font-inter">
            <div className="flex space-x-1">
              <span className="text-sm">Built on</span>
            </div>
            <span className="text-xl font-bold">Flow</span>
          </div>
        </div>

        {/* OpenAI Logo */}
        <div className="flex flex-col items-center px-4">
          <span className="text-sm text-white font-inter">Powered by</span>
          <a
            href="https://openai.com/"
            aria-label="OpenAI logo"
            className="mt-1"
          >
            <img
              src={OpenAILogo}
              alt="OpenAI logo"
              className="h-6 hover:scale-105 transition-transform duration-300"
            />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
