import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import editionNames from "../data/editionNames.json"; // Import edition names
import { parseUnicode } from "./Utilities"; // Import utility for parsing names
import { UserContext } from "./UserContext"; // Import UserContext

// Function to dynamically return edition images from the public folder
const getEditionImage = (id) => {
  return new Promise((resolve) => {
    // Define file paths for both lowercase and uppercase extensions
    const pngImage = `https://storage.googleapis.com/flowconnect/pinnacle/pins/${id}.png`;
    const pngImageUppercase = `https://storage.googleapis.com/flowconnect/pinnacle/pins/${id}.PNG`;
    const webpImage = `https://storage.googleapis.com/flowconnect/pinnacle/pins/${id}.webp`;
    const webpImageUppercase = `https://storage.googleapis.com/flowconnect/pinnacle/pins/${id}.WEBP`;
    const gifImage = `https://storage.googleapis.com/flowconnect/pinnacle/pins/${id}.gif`;

    // Try WEBP first (both lowercase and uppercase)
    const webpImg = new Image();
    webpImg.src = webpImage;

    webpImg.onload = () => {
      resolve(webpImage); // Resolve if lowercase webp loads
    };
    webpImg.onerror = () => {
      // Check if uppercase WEBP exists
      const webpImgUppercase = new Image();
      webpImgUppercase.src = webpImageUppercase;

      webpImgUppercase.onload = () => {
        resolve(webpImageUppercase); // Resolve if uppercase WEBP loads
      };
      webpImgUppercase.onerror = () => {
        // If WEBP fails, try PNG (both lowercase and uppercase)
        const pngImg = new Image();
        pngImg.src = pngImage;

        pngImg.onload = () => {
          resolve(pngImage); // Resolve if lowercase PNG loads
        };
        pngImg.onerror = () => {
          const pngImgUppercase = new Image();
          pngImgUppercase.src = pngImageUppercase;

          pngImgUppercase.onload = () => {
            resolve(pngImageUppercase); // Resolve if uppercase PNG loads
          };
          pngImgUppercase.onerror = () => {
            // If PNG fails, try GIF
            const gifImg = new Image();
            gifImg.src = gifImage;

            gifImg.onload = () => {
              resolve(gifImage); // Resolve GIF if it loads
            };
            gifImg.onerror = () => {
              // If all fail, resolve with placeholder
              resolve(
                "https://storage.googleapis.com/flowconnect/pinnacle/pins/placeholder.png"
              );
            };
          };
        };
      };
    };
  });
};

// Function to determine background color based on edition type
const getSetBackground = (editionType) => {
  switch (editionType) {
    case "Genesis Edition":
      return "bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 animate-slow-wave";
    case "Starter Edition":
      return "bg-indigo-400";
    case "Open Edition":
      return "bg-blue-300";
    case "Limited Edition":
      return "bg-purple-800";
    case "Limited Event Edition":
      return "bg-cyan-500";
    default:
      return "bg-gray-100";
  }
};

const PinnacleSets = () => {
  const { setId } = useParams(); // Get setId from the URL
  const navigate = useNavigate(); // useNavigate hook for navigation
  const [setData, setSetData] = useState(null);
  const [editions, setEditions] = useState([]);
  const [error, setError] = useState(null); // Add an error state
  const [editionImages, setEditionImages] = useState({}); // Store images for each edition
  const [vaultData, setVaultData] = useState({
    CapsuleVault: {},
    PromoVault: {},
    PinnacleVault: {},
  });
  const [burnData, setBurnData] = useState({});
  const { user, nftCollection } = useContext(UserContext); // Get user and NFT collection from context

  // Fetch set details, editions, vaults, and burn data based on setId
  useEffect(() => {
    const fetchSetData = async () => {
      try {
        // Fetch the set data
        const setRes = await fetch(
          `https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-sets`
        );
        const setsData = await setRes.json();
        const currentSet = setsData.find((set) => set.id === Number(setId));

        if (!currentSet) {
          throw new Error(`Set with ID ${setId} not found`);
        }

        setSetData(currentSet);

        // Fetch all editions
        const editionsRes = await fetch(
          `https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-editions`
        );
        const allEditions = await editionsRes.json();
        const filteredEditions = allEditions.filter(
          (edition) => edition.setID === Number(setId)
        );
        setEditions(filteredEditions);

        // Load images for each edition
        const imagePromises = filteredEditions.map((edition) =>
          getEditionImage(edition.id).then((src) => ({ [edition.id]: src }))
        );

        const images = await Promise.all(imagePromises);
        const imagesMap = Object.assign({}, ...images);
        setEditionImages(imagesMap);

        // Fetch CapsuleVault and PromoVault data from the custom backend
        const vaultRes = await fetch(
          `https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-vaults`
        );
        const vaultData = await vaultRes.json();
        const vaultDataMap = vaultData.reduce(
          (acc, vault) => {
            if (vault.vault_address === "0xb6f2481eba4df97b") {
              acc.CapsuleVault[vault.editionID] = vault.quantity;
            } else if (vault.vault_address === "0xd708cc29ee72b8ee") {
              acc.PromoVault[vault.editionID] = vault.quantity;
            }
            return acc;
          },
          { CapsuleVault: {}, PromoVault: {}, PinnacleVault: {} }
        );

        // Fetch PinnacleVault data from the flipsidecrypto API
        const pinnacleVaultRes = await fetch(
          `https://flipsidecrypto.xyz/api/v1/queries/83a51bfc-3d5c-408e-85c4-0bf0a0109544/data/latest`
        );
        const pinnacleVaultData = await pinnacleVaultRes.json();
        pinnacleVaultData.forEach((vault) => {
          if (vault.VAULT_ADDRESS === "0xedf9df96c92f4595") {
            vaultDataMap.PinnacleVault[vault.EDITIONID] = vault.QUANTITY;
          }
        });

        setVaultData(vaultDataMap);

        // Fetch Burn data from the external API
        const burnRes = await fetch(
          `https://flipsidecrypto.xyz/api/v1/queries/cdb2b538-ad5f-46a4-957d-c17f52e044fa/data/latest`
        );
        const burnData = await burnRes.json();
        const burnDataMap = burnData.reduce((acc, burn) => {
          acc[burn.EDITIONID] = burn.BURN_COUNT;
          return acc;
        }, {});
        setBurnData(burnDataMap);
      } catch (error) {
        setError(error.message); // Set the error message
      }
    };

    fetchSetData();
  }, [setId]);

  if (error) {
    return <div className="p-8 text-red-500">Error: {error}</div>; // Show error message if any error occurs
  }

  if (!setData) {
    return <div>Loading...</div>; // Show loading state while fetching data
  }

  // Function to calculate circulated pins
  const calculateCirculated = (
    minted = 0,
    capsuleVault = 0,
    promoVault = 0,
    burn = 0,
    pinnacleVault = 0
  ) => {
    return minted - capsuleVault - promoVault - burn - pinnacleVault;
  };

  // Function to format the date
  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp * 1000); // Convert UNIX timestamp
    return date.toLocaleDateString(); // Format as mm/dd/yyyy
  };

  return (
    <div className="p-8">
      {/* Back Button */}
      <button
        className="mb-4 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
        onClick={() => navigate("/pinnacle")}
      >
        ← Back
      </button>

      {/* Display the parsed set name */}
      <h1 className="text-3xl font-bold mb-4">{parseUnicode(setData.name)}</h1>

      {/* Display the edition type without label */}
      <p className="text-xl mb-4">{setData.editionType}</p>

      {/* Display editions for this set */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-2">
        {editions.map((edition) => {
          const ownedCount =
            user?.loggedIn && nftCollection?.[edition.id]
              ? nftCollection[edition.id].length
              : 0;
          const capsuleVault = vaultData.CapsuleVault?.[edition.id] || 0;
          const promoVault = vaultData.PromoVault?.[edition.id] || 0;
          const pinnacleVault = vaultData.PinnacleVault?.[edition.id] || 0;
          const burnCount = burnData?.[edition.id] || 0;

          return (
            <div
              key={edition.id}
              className={`relative border px-4 py-2 rounded-lg shadow-md text-left flex flex-col justify-between transition-transform duration-300 hover:scale-105 cursor-pointer ${getSetBackground(
                setData.editionType
              )} text-slate-200 ${
                ownedCount > 0 ? "border-4 border-green-400" : ""
              }`}
              onClick={() => navigate(`/pinnacle/editions/${edition.id}`)} // Add click to navigate to edition page
            >
              {/* Total Owned in Green Circle */}
              {ownedCount > 0 && (
                <div className="absolute top-2 left-2 bg-green-500 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold z-10">
                  {ownedCount}X
                </div>
              )}

              <div>
                {/* Edition Image */}
                <img
                  src={editionImages[edition.id] || "/images/placeholder.png"}
                  alt={`Edition ${edition.id}`}
                  className="w-auto h-48 mx-auto mb-4 object-cover"
                />
                <h3 className="text-lg font-bold text-center">
                  {editionNames[edition.id] || "Unknown Edition"}{" "}
                  {edition.isChaser && (
                    <span className="text-yellow-500"> ★</span>
                  )}
                </h3>
                <p className="text-sm mt-2">
                  <strong>EditionID:</strong> {edition.id} &nbsp;
                  <strong>Variant:</strong> {edition.variant}
                </p>
                <div className="text-sm">
                  {edition.traits?.Materials?.length > 0 && (
                    <p>
                      <strong>Materials:</strong>{" "}
                      {edition.traits?.Materials?.join(", ")}
                    </p>
                  )}
                  {edition.traits?.Effects?.length > 0 && (
                    <p>
                      <strong>Effects:</strong>{" "}
                      {edition.traits?.Effects?.join(", ")}
                    </p>
                  )}
                </div>

                {/* Creation and Closed Dates */}
                <p className="text-sm mt-2">
                  <strong>Creation Date:</strong>{" "}
                  {formatDate(edition.creationDate)}
                </p>
                {edition.closedDate && (
                  <p className="text-sm">
                    <strong>Closed Date:</strong>{" "}
                    {formatDate(edition.closedDate)}
                  </p>
                )}
              </div>

              {/* Table with minting, burn, and circulation data */}
              <table className="w-full mt-4 text-left text-sm">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody className="text-black">
                  <tr className="bg-green-100">
                    <td>Minted</td>
                    <td>{edition.numberMinted || 0}</td>
                  </tr>
                  <tr className="bg-yellow-100">
                    <td>CapsuleVault</td>
                    <td>{capsuleVault}</td>
                  </tr>
                  <tr className="bg-purple-100">
                    <td>PromoVault</td>
                    <td>{promoVault}</td>
                  </tr>
                  <tr className="bg-blue-100">
                    <td>PinnacleVault</td>
                    <td>{pinnacleVault}</td>
                  </tr>
                  <tr className="bg-red-100">
                    <td>Burn</td>
                    <td>{burnCount}</td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td>Circulated</td>
                    <td>
                      {calculateCirculated(
                        edition.numberMinted || 0,
                        capsuleVault,
                        promoVault,
                        burnCount,
                        pinnacleVault
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PinnacleSets;
