import React from "react";
import FlowCommunitiesLogo from "../logos/FlowCommunities.svg";
import EventCalendar from "./EventCalendar";
import communities from "../data/communities.js";

const trackClick = (category, label) => {
  if (window.gtag) {
    window.gtag("event", "click", {
      event_category: category,
      event_label: label,
    });
  }
};

const CommunitySection = ({
  logo,
  name,
  links,
  communityLink,
  background,
  logoBackground,
  teamLogo,
  teamLogoBackground,
}) => (
  <div
    className={`flex flex-col lg:flex-row items-center ${background} p-4 rounded-lg lg:w-auto`}
  >
    {/* Logo Section */}
    <a
      href={communityLink}
      target="_blank"
      rel="noopener noreferrer"
      className="hover:scale-105 transition-transform duration-300 flex-shrink-0"
      onClick={() => trackClick("Community Logo", `${name} Logo`)} // Track logo click
    >
      <div
        className={`p-1 rounded-full flex items-center justify-center h-24 w-24 ${logoBackground}`}
      >
        <img
          src={logo}
          alt={`${name} logo`}
          className="h-20 w-20 object-contain rounded-full"
        />
      </div>
    </a>

    {/* Team Logo if exists */}
    {teamLogo && (
      <div
        className={`h-12 w-12 mr-2 rounded-full p-1 border border-gray-300 ${teamLogoBackground} flex-shrink-0`}
      >
        <img
          src={teamLogo}
          alt={`${name} Team logo`}
          className="h-full w-full object-contain rounded-full"
        />
      </div>
    )}

    {/* Social Icons Section */}
    <div className={`mt-4 lg:ml-4 grid grid-cols-2 gap-2 lg:grid-cols-2`}>
      {links.map((item) => (
        <a
          key={item.name}
          href={item.link}
          className="text-white text-2xl hover:scale-105 transition-transform duration-300 text-center"
          target="_blank"
          rel="noopener noreferrer"
          onClick={
            () => trackClick("Social Icon", `${name} - ${item.name} Icon`) // Track social icon click
          }
        >
          {item.icon}
        </a>
      ))}
    </div>
  </div>
);

const CategoryContainer = ({ subcategoryName, communityList }) => (
  <div className="border border-gray-400 p-4 rounded-lg bg-gray-300 flex flex-col flex-grow">
    {/* Subcategory Name */}
    <div className="text-2xl font-bold text-center text-white mb-4">
      {subcategoryName}
    </div>

    {/* Communities within Subcategory */}
    <div className="flex flex-wrap gap-4 justify-start">
      {communityList.map((community) => (
        <CommunitySection
          key={community.name}
          logo={community.logo}
          name={community.name}
          communityLink={community.link}
          links={community.Official}
          background={community.background}
        />
      ))}
    </div>
  </div>
);

const CommunitiesDisplay = () => {
  const smallCategories = [];
  const largeCategories = [];

  // Separate categories based on the number of items in each community list
  Object.entries(communities?.Communities?.subcategories || {}).forEach(
    ([subcategoryName, communityList]) => {
      if (communityList.length < 3) {
        smallCategories.push({ subcategoryName, communityList });
      } else {
        largeCategories.push({ subcategoryName, communityList });
      }
    }
  );

  return (
    <div className="p-2 bg-gray-500">
      <div className="p-2 rounded-lg">
        <img
          src={FlowCommunitiesLogo}
          alt="Flow Communities"
          className="h-12 m-0"
          onClick={() => trackClick("Logo", "Flow Communities Logo")} // Track Flow Communities logo click
        />
      </div>

      {/* Display large categories */}
      <div className="space-y-8">
        {largeCategories.map(({ subcategoryName, communityList }) => (
          <CategoryContainer
            key={subcategoryName}
            subcategoryName={subcategoryName}
            communityList={communityList}
          />
        ))}

        {/* Combine small categories into a single row */}
        {smallCategories.length > 0 && (
          <div className="flex flex-wrap gap-4 justify-start">
            {smallCategories.map(({ subcategoryName, communityList }) => (
              <div
                key={subcategoryName}
                className="inline-flex border border-gray-400 p-4 rounded-lg bg-gray-300 flex-col"
              >
                <CategoryContainer
                  subcategoryName={subcategoryName}
                  communityList={communityList}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunitiesDisplay;
