// UserContext.js
import React, { createContext, useState, useEffect, useCallback } from "react";
import * as fcl from "@onflow/fcl";

// Create the UserContext
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [nftCollection, setNftCollection] = useState({});
  const [editionData, setEditionData] = useState([]);
  const [completedSetIDs, setCompletedSetIDs] = useState([]);
  const [summary, setSummary] = useState({ seriesSummary: {}, totalSets: 0 });
  const [loading, setLoading] = useState(false);

  // Subscribe to the current user and fetch NFTs if logged in
  useEffect(() => {
    fcl.currentUser().subscribe((currentUser) => {
      setUser(currentUser?.addr ? currentUser : null);
      if (currentUser?.addr) {
        fetchUserNFTs(currentUser.addr);
      } else {
        resetUserState(); // Reset user state when logged out
      }
    });
  }, []);

  const resetUserState = () => {
    setNftCollection({});
    setCompletedSetIDs([]);
    setSummary({ seriesSummary: {}, totalSets: 0 });
  };

  // Fetch the user's NFT collection and completed sets
  const fetchUserNFTs = async (userAddress) => {
    if (!userAddress) return;

    try {
      setLoading(true);

      const nftResult = await fcl.query({
        cadence: `import NonFungibleToken from 0x1d7e57aa55817448
                  import Pinnacle from 0xedf9df96c92f4595
                  import HybridCustody from 0xd8a7e05a7ac670c0

                  access(all) struct NFTDetails {
                    access(all) let id: UInt64
                    access(all) let editionID: Int
                    access(all) let serialNumber: UInt64?

                    init(id: UInt64, editionID: Int, serialNumber: UInt64?) {
                        self.id = id
                        self.editionID = editionID
                        self.serialNumber = serialNumber
                    }
                  }

                  access(all) fun getNFTsFromAddress(address: Address): [NFTDetails] {
                    let account = getAccount(address)

                    let collectionRef = account
                        .capabilities
                        .borrow<&Pinnacle.Collection>(/public/PinnacleCollection)

                    if collectionRef == nil {
                        return []
                    }

                    let nftIDs = collectionRef!.getIDs()
                    var nftDetailsList: [NFTDetails] = []

                    for id in nftIDs {
                        let nftRef = collectionRef!.borrowPinNFT(id: id)
                            ?? panic("Could not borrow a reference to the NFT")

                        let nftDetails = NFTDetails(
                            id: nftRef.id,
                            editionID: nftRef.editionID,
                            serialNumber: nftRef.serialNumber
                        )

                        nftDetailsList.append(nftDetails)
                    }

                    return nftDetailsList
                  }

                  access(all) fun main(address: Address): [NFTDetails] {
                    var allNftDetails: [NFTDetails] = []

                    let parentNFTs = getNFTsFromAddress(address: address)
                    allNftDetails.appendAll(parentNFTs)

                    let acct = getAuthAccount<auth(Storage) &Account>(address)
                    let manager = 
                        acct.storage.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                        ?? panic("Manager not found")

                    let childAddresses = manager.getChildAddresses()

                    for childAddress in childAddresses {
                        let childNFTs = getNFTsFromAddress(address: childAddress)
                        allNftDetails.appendAll(childNFTs)
                    }

                    return allNftDetails
                  }
        `,
        args: (arg, t) => [arg(userAddress, t.Address)],
      });

      const editionsRes = await fetch(
        "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-editions"
      ).then((res) => res.json());

      const groupedNFTs = groupByEditionID(nftResult || []);
      const completedSetIDs = calculateCompletedSets(groupedNFTs, editionsRes);
      const summary = calculateSummary(groupedNFTs, editionsRes);

      setNftCollection(groupedNFTs);
      setEditionData(editionsRes);
      setCompletedSetIDs(completedSetIDs);
      setSummary(summary); // Update the summary
    } catch (error) {
      console.error("Error fetching user's NFTs:", error);
    } finally {
      setLoading(false);
    }
  };

  // Group NFTs by editionID
  const groupByEditionID = useCallback((nfts) => {
    const grouped = {};
    nfts.forEach((nft) => {
      if (!grouped[nft.editionID]) {
        grouped[nft.editionID] = [];
      }
      grouped[nft.editionID].push(nft);
    });
    return grouped;
  }, []);

  // Calculate completed sets based on owned editions
  const calculateCompletedSets = (nftGrouped, editions) => {
    const completedSetIDs = new Set();

    // Map setID to its required editionIDs
    const setsToEditionIDs = editions.reduce((acc, edition) => {
      if (!acc[edition.setID]) {
        acc[edition.setID] = [];
      }
      acc[edition.setID].push(edition.id); // Push editionID to the set
      return acc;
    }, {});

    // Check if user owns all editionIDs for each setID
    Object.keys(setsToEditionIDs).forEach((setID) => {
      const requiredEditionIDs = setsToEditionIDs[setID];
      const ownedAllEditions = requiredEditionIDs.every(
        (editionID) => nftGrouped[editionID]?.length > 0
      );
      if (ownedAllEditions) {
        completedSetIDs.add(parseInt(setID)); // Mark the set as completed
      }
    });

    return Array.from(completedSetIDs);
  };

  // Calculate summary for completed sets
  const calculateSummary = (nftGrouped, editions) => {
    const summary = {
      seriesSummary: {}, // Summary by series
      totalSets: 0, // Total sets completed
    };

    const setsToEditionIDs = editions.reduce((acc, edition) => {
      if (!acc[edition.setID]) {
        acc[edition.setID] = [];
      }
      acc[edition.setID].push(edition.id); // Push editionID to the set
      return acc;
    }, {});

    Object.keys(setsToEditionIDs).forEach((setID) => {
      const requiredEditionIDs = setsToEditionIDs[setID];
      const ownedAllEditions = requiredEditionIDs.every(
        (editionID) => nftGrouped[editionID]?.length > 0
      );

      if (ownedAllEditions) {
        const seriesID = editions.find(
          (edition) => edition.setID === parseInt(setID)
        )?.seriesID;
        if (!summary.seriesSummary[seriesID]) {
          summary.seriesSummary[seriesID] = 0;
        }
        summary.seriesSummary[seriesID]++;
        summary.totalSets++;
      }
    });

    return summary;
  };

  // Handle login and logout
  const logIn = async () => {
    try {
      await fcl.authenticate();
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  const logOut = async () => {
    try {
      await fcl.unauthenticate();
      resetUserState(); // Reset user state on logout
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        nftCollection,
        editionData,
        completedSetIDs,
        summary,
        loading,
        logIn,
        logOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
