// src/Utilities.js

export const getSetBackground = (editionType) => {
  switch (editionType) {
    case "Genesis Edition":
      return "bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 animate-slow-wave";
    case "Starter Edition":
      return "bg-gradient-to-r from-gray-400 to-gray-600";
    case "Open Edition":
      return "bg-gradient-to-r from-blue-200 to-blue-300";
    case "Limited Edition":
      return "bg-gradient-to-r from-green-300 to-blue-500";
    default:
      return "bg-gray-100";
  }
};

export const formatDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString();
};

export const calculatePercentage = (total, part) => {
  return total > 0 ? (part / total) * 100 : 0;
};

export const formatNumberWithCommas = (number) => {
  if (isNaN(number) || number === null || number === undefined) {
    return "0"; // Return "0" if the input is not a valid number
  }
  return number.toLocaleString();
};

export const parseUnicode = (text) => {
  return text.replace(/\\u\{([0-9A-Fa-f]+)\}/g, (_, code) =>
    String.fromCharCode(parseInt(code, 16))
  );
};

export const FullCircle = ({
  circulatedPercentage,
  vault1Percentage,
  vault2Percentage,
  burnsPercentage,
}) => {
  return (
    <div className="relative w-36 h-36">
      <div
        className="absolute w-36 h-36 rounded-full border-4 border-gray-300"
        style={{
          background: `conic-gradient(
              #86efac ${circulatedPercentage}%, 
              #fcd34d ${circulatedPercentage}% ${
            circulatedPercentage + vault1Percentage
          }%, 
              #bfdbfe ${circulatedPercentage + vault1Percentage}% ${
            circulatedPercentage + vault1Percentage + vault2Percentage
          }%, 
              #fecaca ${
                circulatedPercentage + vault1Percentage + vault2Percentage
              }% 100%
            )`,
        }}
      />
    </div>
  );
};
