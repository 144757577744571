import * as fcl from "@onflow/fcl";
import { init } from "@onflow/fcl-wc";

// Setup Flow SDK and WalletConnect
export async function setupFclConfigAndWalletConnect() {
  // Flow SDK configuration
  fcl
    .config()
    .put("accessNode.api", "https://rest-mainnet.onflow.org") // Mainnet HTTP access
    .put("discovery.wallet", "https://fcl-discovery.onflow.org/authn") // Wallet service for Flow Mainnet
    .put(
      "discovery.authn.endpoint",
      "https://fcl-discovery.onflow.org/api/authn"
    )
    .put("walletconnect.projectId", "1c74f5c9166063f0f3d2eb0cc6b30265") // WalletConnect project ID
    .put("app.detail.title", "FlowConnect") // Your app's title
    .put("app.detail.icon", "https://flowconnect.io/logo.png") // Your app's icon URL
    .put("app.detail.description", "Connecting Flow Communities") // Your app's description
    .put("app.detail.url", "https://flowconnect.io"); // Your app's URL

  // WalletConnect configuration
  const { FclWcServicePlugin } = await init({
    projectId: "1c74f5c9166063f0f3d2eb0cc6b30265", // Your WalletConnect project ID
    metadata: {
      name: "FlowConnect",
      description: "Connecting Flow Communities",
      url: "https://flowconnect.io",
      icons: ["https://flowconnect.io/logo.png"],
    },
    includeBaseWC: true, // Includes a basic WalletConnect service
    wcRequestHook: (wcRequestData) => {
      console.log("WalletConnect request:", wcRequestData);
    }, // Optional: Hook to handle signing requests
    pairingModalOverride: (uri, rejectPairingRequest) => {
      console.log("Override pairing modal with URI:", uri);
    }, // Optional: Custom handling for QR code pairing modal
  });

  // Register the WalletConnect plugin with FCL
  fcl.pluginRegistry.add(FclWcServicePlugin);
}
