import React from "react";
import FlowTimeLogo from "../logos/FlowTimeline.svg";
import FlowCommunitiesLogo from "../logos/FlowCommunities.svg";
import PinnacleDiscoveryLogo from "../logos/PinnacleDiscovery.svg";
import EventCalendar from "./EventCalendar";
import NewsFeed from "./NewsFeed";
import CryptoTicker from "./CryptoTicker";

function HomePage() {
  // Google Analytics event tracking
  const trackEventClick = (category, label) => {
    if (window.gtag) {
      window.gtag("event", "click", {
        event_category: category,
        event_label: label,
      });
    }
  };

  return (
    <div className="p-2 bg-white">
      {/* Main Content */}
      <div className="flex flex-wrap">
        {/* Main News Section */}
        <div className="w-full md:w-[65%]">
          {/* Display the News Feed */}
          <NewsFeed />
        </div>

        {/* Sidebar */}
        <div className="w-full md:w-[35%] p-2">
          <div className="mb-2">
            <CryptoTicker />
          </div>
          <div>
            <EventCalendar todayOnly={true} />
          </div>
          {/* Highlighted Projects Section */}
          <div className="mt-8">
            <h3 className="text-xl font-bold text-purple-900 mb-4">
              Projects to Explore
            </h3>

            <div className="bg-gradient-to-r from-gray-100 to-gray-200 p-4 rounded-lg shadow-lg mb-4">
              <a
                href="/pinnacle-discovery"
                className="block text-center text-blue-500 hover:scale-105 transition-transform duration-300"
                onClick={() =>
                  trackEventClick("Project Link", "Pinnacle Discovery")
                } // Track Pinnacle Discovery link click
              >
                <img
                  src={PinnacleDiscoveryLogo}
                  alt="Discover Communities"
                  className="mx-auto mb-2 w-32"
                />
                <p>Discover and explore the Disney Pinnacle project.</p>
              </a>
            </div>

            <div className="bg-gradient-to-r from-blue-300 to-blue-500 p-4 rounded-lg shadow-lg">
              <a
                href="/communities"
                className="block text-center text-white hover:scale-105 transition-transform duration-300"
                onClick={() =>
                  trackEventClick("Project Link", "Flow Communities")
                } // Track Flow Communities link click
              >
                <img
                  src={FlowCommunitiesLogo}
                  alt="Discover Communities"
                  className="mx-auto mb-2 w-32"
                />
                <p>
                  Discover and connect with active communities within the Flow
                  ecosystem.
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Calendar Section at the Bottom */}
      <div className="mt-6">
        <h2 className="text-3xl font-bold text-center text-purple-900 mb-1">
          Upcoming Events
        </h2>
        <EventCalendar />
      </div>
    </div>
  );
}

export default HomePage;
