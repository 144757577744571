import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Timeline from "./components/Timeline";
import Footer from "./components/Footer";
import Communities from "./components/Communities";
import Pinnacle from "./components/Pinnacle";
import Collection from "./components/Collection";
import PinnacleSets from "./components/PinnacleSets";
import PinnacleEditions from "./components/PinnacleEditions";
import { UserProvider } from "./components/UserContext";
import NewsFeed from "./components/NewsFeed";
import { setupFclConfigAndWalletConnect } from "./config"; // Import the combined config setup
import "./App.css";

function App() {
  const location = useLocation(); // Hook to get current route

  // Call FCL and WalletConnect setup when app loads
  useEffect(() => {
    setupFclConfigAndWalletConnect(); // Ensure Flow SDK & WalletConnect are initialized
  }, []); // Only run once when the app starts

  // Google Analytics setup
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-X7QKSZ0G7R";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-X7QKSZ0G7R');
    `;
    document.head.appendChild(script2);
  }, []);

  // Track page views on route changes
  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-X7QKSZ0G7R", {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <UserProvider>
      <div className="flex flex-col min-h-screen bg-cover bg-center">
        <Header />
        <main className="flex-grow transition-opacity duration-300">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/timeline" element={<Timeline />} />
            <Route path="/communities" element={<Communities />} />
            <Route path="/pinnacle" element={<Pinnacle />} />
            <Route path="/collection" element={<Collection />} />
            <Route path="/news" element={<NewsFeed />} />
            <Route path="pinnacle/sets/:setId" element={<PinnacleSets />} />
            <Route
              path="pinnacle/editions/:editionId"
              element={<PinnacleEditions />}
            />
          </Routes>
        </main>
        <Footer className="mt-auto" />
      </div>
    </UserProvider>
  );
}

export default App;
