import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import * as fcl from "@onflow/fcl";
import Logo from "../logos/FlowConnect.svg";
import FlowTimeLogo from "../logos/FlowTimeline.svg";
import FlowCommunitiesLogo from "../logos/FlowCommunities.svg";
import PinnacleDiscoveryLogo from "../logos/PinnacleDiscovery.svg";
import { FiMenu, FiX, FiUser, FiLogOut, FiCopy, FiGrid } from "react-icons/fi";
import { UserContext } from "./UserContext"; // Import UserContext

function Header() {
  const { user, logIn, logOut } = useContext(UserContext); // Access user context
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false); // State for profile dropdown
  const [copied, setCopied] = useState(false); // State to track copy action

  // Function to copy wallet address to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(user.addr);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <>
      <nav className="bg-gray-400 p-4 flex justify-between items-center">
        <NavLink
          to="/"
          className="flex flex-col items-center ml-4 hover:scale-105 transition-transform duration-300"
        >
          <img src={Logo} alt="Flow Connect logo" className="h-16 sm:h-16" />
        </NavLink>

        {/* Hamburger Menu Icon for Mobile */}
        <div className="lg:hidden">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-gray-700 focus:outline-none"
          >
            {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>

        {/* Menu Items */}
        <div className="hidden lg:flex space-x-8 items-center">
          <NavLink
            to="/pinnacle"
            className="px-4 py-2 bg-gray-100 rounded-md hover:scale-105 transition-transform duration-300 flex items-center justify-center"
          >
            <img
              src={PinnacleDiscoveryLogo}
              alt="Pinnacle Discovery"
              className="h-4 sm:h-6"
            />
          </NavLink>

          <NavLink
            to="/communities"
            className="px-4 py-2 bg-gradient-to-r from-blue-300 to-blue-500 rounded-md hover:scale-105 transition-transform duration-300 flex items-center justify-center"
          >
            <img
              src={FlowCommunitiesLogo}
              alt="FlowCommunities"
              className="h-4 sm:h-6"
            />
          </NavLink>

          {/* If the user is logged in, show profile icon */}
          {user?.addr ? (
            <div className="relative">
              {/* Profile Icon */}
              <button
                onClick={() => setProfileOpen(!profileOpen)}
                className="flex items-center justify-center text-gray-700 focus:outline-none hover:scale-105 transition-transform duration-300"
              >
                <FiUser size={24} />
              </button>

              {/* Profile Dropdown */}
              {profileOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg p-4 z-10">
                  <p className="text-gray-700 font-semibold mb-2">
                    Wallet Address
                  </p>
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-gray-600">{user.addr}</span>
                    <button
                      onClick={copyToClipboard}
                      className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      <FiCopy size={18} />
                    </button>
                  </div>
                  {copied && (
                    <p className="text-green-500 text-xs mb-2">
                      Address copied!
                    </p>
                  )}
                  <NavLink
                    to="/collection"
                    className="flex items-center bg-blue-500 text-white rounded-md px-4 py-2 hover:scale-105 hover:bg-blue-600 transition-transform duration-300 mb-2"
                    onClick={() => setProfileOpen(false)}
                  >
                    <FiGrid size={18} className="mr-2" />
                    My Collection
                  </NavLink>
                  <button
                    onClick={logOut}
                    className="flex items-center bg-red-500 text-white rounded-md px-4 py-2 hover:scale-105 transition-transform duration-300"
                  >
                    <FiLogOut size={18} className="mr-2" />
                    Sign Out
                  </button>
                </div>
              )}
            </div>
          ) : (
            <button
              onClick={logIn}
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:scale-105 transition-transform duration-300"
            >
              Login
            </button>
          )}
        </div>
      </nav>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="lg:hidden flex flex-col items-center bg-gray-400 shadow-lg rounded-md p-4 space-y-4 w-full px-6">
          <NavLink
            to="/pinnacle"
            className="px-6 py-2 bg-gray-100 rounded-md hover:scale-105 transition-transform duration-300 flex items-center justify-center w-full max-w-[90%]"
            onClick={() => setMenuOpen(false)}
          >
            <img
              src={PinnacleDiscoveryLogo}
              alt="Pinnacle Discovery"
              className="h-6 sm:h-8 mx-auto"
            />
          </NavLink>

          <NavLink
            to="/communities"
            className="px-6 py-2 bg-gradient-to-r from-blue-300 to-blue-500 rounded-md hover:scale-105 transition-transform duration-300 flex items-center justify-center w-full max-w-[90%]"
            onClick={() => setMenuOpen(false)}
          >
            <img
              src={FlowCommunitiesLogo}
              alt="FlowCommunities"
              className="h-6 sm:h-8 mx-auto"
            />
          </NavLink>

          {/* If the user is logged in, show My Collection in Mobile Menu */}
          {user?.addr && (
            <NavLink
              to="/collection"
              className="px-6 py-2 bg-blue-500 text-white rounded-md hover:scale-105 hover:bg-blue-600 transition-transform duration-300 flex items-center justify-center w-full max-w-[90%]"
              onClick={() => setMenuOpen(false)}
            >
              <FiGrid size={18} className="mr-2" />
              My Collection
            </NavLink>
          )}

          {/* Login/Logout Button */}
          {user?.addr ? (
            <button
              onClick={logOut}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:scale-105 transition-transform duration-300"
            >
              Log Out ({user.addr})
            </button>
          ) : (
            <button
              onClick={logIn}
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:scale-105 transition-transform duration-300"
            >
              Login
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default Header;
