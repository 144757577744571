export const content = [
  {
    year: 2012,
    month: "May",
    iconType: "AxiomZen",
    type: "NewProduct",
    title: "Axiom Zen Founded",
    details:
      "Axiom Zen is founded by Roham Gharegozlou and a team of innovators in Vancouver, Canada. The venture studio focuses on creating technology startups and products in fields like artificial intelligence, blockchain, and mixed reality.",
  },
  {
    year: 2017,
    month: "October",
    iconType: "CryptoKitties",
    type: "NewProduct",
    title: "CryptoKitties Unveiled",
    details:
      "CryptoKitties is unveiled at ETH Waterloo, an Ethereum hackathon, marking one of the first blockchain games designed for recreation.",
  },
  {
    year: 2017,
    month: "November",
    iconType: "CryptoKitties",
    type: "NewProduct",
    title: "CryptoKitties Officially Launched",
    details:
      "CryptoKitties officially launches on Ethereum, quickly gaining popularity and congesting the network with its success.",
  },
  {
    year: 2017,
    month: "December",
    iconType: "CryptoKitties",
    type: "Issues",
    title: "Ethereum Congestion Issues",
    details:
      "CryptoKitties' popularity explodes, congesting the Ethereum network and causing transaction delays as it accounts for over 25% of the network's traffic.",
  },
  {
    year: 2018,
    month: "March",
    iconType: "DapperLabs",
    type: "Funding",
    title: "Funding Round",
    details:
      "Dapper Labs, a spin-off from Axiom Zen, raises $12 million in its first funding round, led by Union Square Ventures and Andreessen Horowitz.",
  },
  {
    year: 2018,
    month: "November",
    iconType: "DapperLabs",
    type: "Funding",
    title: "Funding Round",
    details:
      "Dapper Labs raises an additional $15 million in funding, doubling its valuation. This round was led by Venrock, with participation from GV (formerly Google Ventures) and Samsung Next.",
    link: "https://www.prnewswire.com/news-releases/dapper-labs-the-company-behind-cryptokitties-raises-15m-in-financing-to-drive-consumer-adoption-of-blockchain-technology-300743175.html",
  },
  {
    year: 2019,
    month: "July",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "NBA, NBPA and Dapper Labs Partnership",
    details:
      "Dapper Labs partners with the NBA and NBPA to launch NBA Top Shot, a digital platform that allows fans to collect, trade, and own iconic NBA moments using blockchain technology.",
    link: "https://pr.nba.com/nba-nbpa-dapper-labs-blockchain-game/",
  },
  {
    year: 2019,
    month: "September",
    iconType: "DapperLabs",
    type: "NewProduct",
    title: "Dapper Labs Announces Flow Blockchain",
    details:
      "Dapper Labs announces Flow, a blockchain developed to solve scalability issues for consumer applications like games and digital collectibles. Along with the announcement, they raised $11M in funding from major investors like Andreessen Horowitz, Warner Music Group, and Ubisoft.",
    link: "https://www.dapperlabs.com/newsroom/dapper-labs-announces-flow-a-new-blockchain-by-the-creators-of-cryptokitties",
  },
  {
    year: 2020,
    month: "February",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "Dapper Labs Partners with UFC",
    details:
      "Dapper Labs partners with UFC to bring UFC-branded digital collectibles to fans on Flow blockchain, offering opportunities to purchase, trade, and own limited-edition verified assets in real-time.",
    link: "https://www.dapperlabs.com/newsroom/dapper-labs-partners-with-ufc-to-bring-new-digital-collectibles-to-fans-on-blockchain",
  },

  {
    year: 2020,
    month: "June",
    iconType: "TopShot",
    type: "NewProduct",
    title: "NBA Top Shot Offically Launched",
    details:
      "Series 1 Officially started. First moment minted on the Flow Blockchain.",
  },
  {
    year: 2020,
    month: "June",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Ability to Track Moments in Collections",
    details:
      "Automatically tracks acquired Moments and updates collections pages with new additions.",
  },
  {
    year: 2020,
    month: "June",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with Circle and Coinbase",
    details:
      "Financial Integration: Partnerships with Circle and Coinbase for better purchasing options. Enables purchases with fiat or cryptocurrency.",
  },
  {
    year: 2020,
    month: "June",
    iconType: "Flow",
    type: "NewProduct",
    title: "First NFT Minted on Flow",
    details: "First NFT is minted on the Flow Blockchain.",
  },
  {
    year: 2020,
    month: "July",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Challenges",
    details:
      "New gameplay mechanic allowing users to complete specific tasks for rewards.",
  },
  {
    year: 2020,
    month: "July",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "Dr. Seuss Partners with Dapper Labs for Digital Collectibles",
    details:
      "Dr. Seuss Enterprises partners with Dapper Labs to bring iconic characters like The Cat in the Hat and The Lorax to blockchain as digital collectibles, allowing fans to purchase and wholly own digital decals of their favorite characters.",
    link: "https://www.dapperlabs.com/newsroom/dr-seuss-enterprises-partners-with-dapper-labs-to-create-an-entirely-new-digital-collectible-experience-for-fans-around-the-globe",
  },

  {
    year: 2020,
    month: "September",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Showcases",
    details: "Ability to curate moments into showcases.",
  },
  {
    year: 2020,
    month: "September",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Unopened Packs",
    details: "Unopened Packs: Ability to hold unopened packs.",
  },
  {
    year: 2020,
    month: "September",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Crypto Deposits",
    details:
      "Add to your Dapper Balance with crypto deposits for smoother transactions.",
  },
  {
    year: 2020,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "NBA Top Shot opens to the public",
    details: "Open Beta: NBA Top Shot transitions to Open Beta.",
  },
  {
    year: 2020,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Profile Enhancements",
    details:
      "Ability to connect your Twitter handle and Discord name to your TopShot Profile.",
  },
  {
    year: 2020,
    month: "November",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Sales History",
    details: "View recent sales history on moments and listings.",
  },
  {
    year: 2020,
    month: "December",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Serial Numbers Discovery",
    details:
      "Collectors can discover which serial numbers from specific Moments have yet to be pulled from packs.",
  },
  {
    year: 2021,
    month: "January",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Identify Verification",
    details:
      "Added Identify Verification capability, some users may need to undergo an identity check using valid government-issued ID to protect against fraud.",
  },
  {
    year: 2021,
    month: "January",
    iconType: "TopShot",
    type: "Issue",
    title: "Bypassing of Queue",
    details:
      "Technical issue identified during the Series 2 Holo drop allowed some users to bypass the regular queue. This was from cookies from a previous test of the queue drop process.",
  },
  {
    year: 2021,
    month: "March",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Moment Badges",
    details:
      "Rookie Year, Rookie Premiere, Rookie Mint, Three-Star Rookie, Top Shot Debut.",
  },

  {
    year: 2021,
    month: "March",
    iconType: "DapperLabs",
    type: "Funding",
    title: "Dapper Labs Raises $305 Million",
    details:
      "Dapper Labs secures $305 million in funding led by Coatue, with investments from Michael Jordan, Kevin Durant, and more than 30 NBA and NFL players. This round supports the growth of NBA Top Shot and the expansion of the Flow blockchain to other sports and creators.",
    link: "https://www.businesswire.com/news/home/20210330005316/en/Coatue-Michael-Jordan-Kevin-Durant-a16z-and-more-than-30-other-NBA-and-NFL-players-and-VCs-invest-305-million-in-Dapper-Labs-makers-of-NBA-Top-Shot-and-Flow-blockchain",
  },

  //  {
  //   year: 2021,
  //   month: "March",
  //   iconType: "ThirdParty",
  //   type: "Evaluate.market Funding",
  //   details: `Evaluate.market raised $1.6 million in seed funding to build a data analytics platform for NBA Top Shot. <a href="https://www.coindesk.com/nba-top-shot-data-site-raises-1-6m-to-bring-visibility-to-your-nft-bags" target="_blank" class="text-blue-500 underline">Read more</a>`,
  //  },
  {
    year: 2021,
    month: "April",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Challenge Reward Indicator",
    details: "Renovated to stand out more in collections and Marketplace.",
  },
  {
    year: 2021,
    month: "April",
    iconType: "DapperLabs",
    type: "NewProduct",
    title: "Genies Marketplace on Flow Blockchain",
    details:
      "Dapper Labs partners with Genies to launch the 'Genies Marketplace' on Flow, allowing users to buy, sell, and trade celebrity avatar wearables as NFTs. The platform enables users to purchase exclusive digital wearable collections from celebrities and influencers.",
    link: "https://www.dapperlabs.com/newsroom/sneak-peek-into-the-genies-marketplace-the-next-big-project-on-flow-after-nba-top-shot",
  },

  {
    year: 2021,
    month: "May",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Trade Tickets",
    details:
      "Trade Moments for Trade Tickets - Use Trade Tickets to acquire exclusive packs.",
  },
  {
    year: 2021,
    month: "May",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Collector Score",
    details:
      "Collector Score: Introduced to reward committed collectors and grant access to limited content.",
  },
  {
    year: 2021,
    month: "May",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Reservation System for Packs",
    details: "Reservation system implemented for collectors to reserve packs.",
  },
  {
    year: 2021,
    month: "June",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Moment Tracker Visualization",
    details:
      "Tracking NBA Top Shot Moments: Moment Tracker Visualization simplifies visualizing how Moments are distributed on the platform. Includes data on Moments in circulation, Moments in packs, and Moments in collections.",
  },
  {
    year: 2021,
    month: "June",
    iconType: "TopShot",
    type: "Personnel",
    title: "New GM: Tristian Rattink",
    details:
      "Introducing our new GM: Tristan Rattink is the new General Manager of NBA Top Shot.",
  },
  {
    year: 2021,
    month: "July",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Championship Badges",
    details:
      "Introduced 'Championship Year' badge for Moments from championship-winning teams.",
  },
  {
    year: 2021,
    month: "July",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Snapshot-based Utility",
    details:
      "Testing a snapshot-based utility system for collectors holding key Moments.",
  },
  {
    year: 2021,
    month: "July",
    iconType: "TopShot",
    type: "Personnel",
    title: "Hired NBA Top Shot Economist",
    details: "Hired NBA Top Shot Economist.",
  },
  {
    year: 2021,
    month: "July",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with Quavo for a new Quest",
    details:
      "Partnered with Quavo for a new Quevo Quest with signed merchandise and legendary packs as rewards.",
  },
  {
    year: 2021,
    month: "July",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with Quavo for NBA Finals",
    details:
      "Introduction of Quavo for a legendary pack release for the NBA Finals.",
  },
  {
    year: 2021,
    month: "July",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with Quavo for a Digital Experience",
    details:
      "You’ll unlock a digital experience with Quavo’s avatar, created by our FLOW partner Genies. The experience will include performances from the new Migos album, Culture III.",
  },
  {
    year: 2021,
    month: "July",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "NBA Draft Experience",
    details:
      "NBA Draft Experience - Eight lucky collectors invited to NBA Draft in NYC by NBA Top Shot - Includes NBA Top Shot Suite access, airfare, accommodations, VIP dinner - Winners selected based on 3-Star Rookie Moments in their collection.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "In-Arena Moments",
    details:
      "Launch of in-arena Moments from NBA Summer League games at the Top Shot Kiosk.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Fandom Tier",
    details: "Bridges common and rare categories.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "TopShot",
    type: "NewProduct",
    title: "Nine Lives Lounge Launch",
    details:
      "Exclusive club within the Top Shot community. Accessible only to collectors with all 30 Cool Cats Moments. Rewarding collectors with 3 packs: Seeing Stars, Cool Cats, and Base Set pack. Host of events for Lounge members, including conversations with NBA stars and Dapper Labs' key personnel.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Pack Recording",
    details: "Record pack opening reactions and share with the community.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Multi-Queue Drop Levels",
    details:
      "Multi-Queue Drop Levels: General Queue, Priority Queue 1 & 2 for securing Rare or Legendary packs.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Starter Packs & Booster Packs",
    details:
      "Seamless beginner packs, opportunity for additional Booster Packs.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Collector Score Real-Time Updates",
    details:
      "Collector Score: Collector Score visible in real-time, updated collection tracking.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Player Checklists by Team",
    details: "Player Checklists by Team: Track owned Moments per NBA team.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "Merchandise",
    type: "Merchandise",
    title: "Limited Edition Merchandise in Las Vegas",
    details:
      "Limited edition merchandise available for collectors in Las Vegas to commemorate the launch of the Summer 2021 series.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "NBA Summer League Access",
    details:
      "NBA Summer League Access: Winners receive tickets, merch, and exclusive experiences at NBA Summer League in Las Vegas.",
  },
  {
    year: 2021,
    month: "August",
    iconType: "TopShot",
    type: "NewProduct",
    title: "WNBA Top Shot Launch",
    details: "WNBA Top Shot: Moments now available from the WNBA on Top Shot.",
  },
  {
    year: 2021,
    month: "September",
    iconType: "DapperLabs",
    type: "Funding",
    title: "Dapper Labs Raises $250 Million",
    details:
      "Dapper Labs, the company behind NBA Top Shot, raises $250 million in a funding round led by Coatue. This brings Dapper Labs' valuation to $7.6 billion. Existing investors, including Andreessen Horowitz, GV, and Version One Ventures, as well as new investors like BOND and GIC, participated in this round.",
    link: "https://techcrunch.com/2021/09/22/nba-top-shot-creator-dapper-labs-raises-another-250-million/",
  },
  {
    year: 2021,
    month: "September",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "Google Cloud Partners with Flow",
    details:
      "Dapper Labs announces a multi-year partnership with Google Cloud to scale Flow blockchain to billions of users. The partnership aims to boost Flow's ability to support mainstream NFT adoption while further reducing energy usage and carbon footprint through Google Cloud's infrastructure.",
    link: "https://www.dapperlabs.com/newsroom/google-cloud-on-board-to-drive-next-wave-of-mainstream-nft-adoption-on-flow",
  },
  {
    year: 2021,
    month: "September",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "LaLiga and Dapper Labs Partnership",
    details:
      "Dapper Labs partners with LaLiga to launch a digital collectible experience for football fans. Built on Flow blockchain, the platform will offer fans the opportunity to collect and trade iconic Moments from LaLiga matches, featuring both current and historical highlights.",
    link: "https://www.dapperlabs.com/newsroom/laliga-joins-forces-with-dapper-labs-to-launch-an-all-new-digital-collectible-experience-for-football-fans-around-the-globe",
  },
  {
    year: 2021,
    month: "September",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "NFL Partners with Dapper Labs for NFT Highlights",
    details:
      "The NFL, NFLPA, and Dapper Labs announce a partnership to create exclusive digital video highlights as NFTs. Fans will be able to collect, trade, and own iconic NFL Moments featuring the best plays from both current stars and legendary players.",
    link: "https://www.dapperlabs.com/newsroom/the-national-football-league-the-nfl-players-association-and-dapper-labs-announce-new-nft-deal-to-create-exclusive-digital-video-highlights",
  },
  {
    year: 2021,
    month: "October",
    iconType: "DapperLabs",
    type: "Acquisition",
    title: "Dapper Labs Acquires Brud",
    details:
      "Dapper Labs acquires Sequoia Capital-backed startup Brud, led by Trevor McFedries. McFedries and his team join Dapper Labs to launch Dapper Collectives, a unit focused on decentralizing social media and enabling community ownership on Flow blockchain.",
    link: "https://www.dapperlabs.com/newsroom/dapper-labs-acquires-brud",
  },
  {
    year: 2021,
    month: "October",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "Chainalysis Brings Compliance Solution to Dapper Labs",
    details:
      "Dapper Labs partners with Chainalysis to use its KYT and Reactor products to enhance compliance processes and ensure safety in the rapidly growing NFT ecosystem. This partnership aims to combat money laundering and market manipulation on Dapper's platforms like NBA Top Shot.",
    link: "https://www.dapperlabs.com/newsroom/chainalysis-brings-leading-compliance-solution-to-dapper-labs",
  },
  {
    year: 2021,
    month: "October",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "Chainalysis Brings Compliance Solution to Dapper Labs",
    details:
      "Dapper Labs partners with Chainalysis to use its KYT and Reactor products to enhance compliance processes and ensure safety in the rapidly growing NFT ecosystem. This partnership aims to combat money laundering and market manipulation on Dapper's platforms like NBA Top Shot.",
    link: "https://www.dapperlabs.com/newsroom/chainalysis-brings-leading-compliance-solution-to-dapper-labs",
  },
  {
    year: 2021,
    month: "October",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "Boardroom and Dapper Labs Announce Multi-Year Deal",
    details:
      "Kevin Durant's Boardroom partners with Dapper Labs to bring creative collaborations, including NBA Top Shot Moments curated by Durant. The partnership spans sponsorship, content creation, and fan engagement opportunities across Boardroom's properties.",
    link: "https://www.dapperlabs.com/newsroom/boardroom-dapper-labs-announce-multi-year-brand-deal",
  },
  {
    year: 2021,
    month: "October",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "Home Opener Experience",
    details:
      "NBA Top Shot Tip-Off Takeover: Collectors sent to NBA Tip-Off between Nets and Bucks, with suite-level tickets and other exclusive experiences.",
  },
  {
    year: 2021,
    month: "October",
    iconType: "TopShot",
    type: "Partnership",
    title: "Kevin Durant Partnership",
    details:
      "Kevin Durant and Boardroom Partnership: KD collaborates on curating NBA Top Shot Moments and video content, expanding his impact on the platform.",
  },
  {
    year: 2021,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Legendary Moments Movable to third-party wallets",
    details:
      "Legendary Moments Utility: Legendary Moments can now be moved to third-party wallets on Flow, part of decentralizing ownership on the platform.",
  },
  {
    year: 2021,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Currency Toggle",
    details: "Ability to toggle between USD, ETH, and FLOW pricing.",
  },
  {
    year: 2021,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Marketplace Open During Pack Drops",
    details:
      "The Marketplace remains open during pack drops, with involved Moments locked from buying and selling.",
  },
  {
    year: 2021,
    month: "October",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with Dwyane Wade",
    details:
      "Dwyane Wade Fandom Moment: Pre-order available for Dwyane Wade Fandom Moment Collectible, part of the Vintage Vibes set.",
  },

  {
    year: 2021,
    month: "October",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "WNBA Finals Experience",
    details:
      "Access Unlocked: 2021 WNBA Finals: Four collectors attend Game 3 in Chicago, with all-access experiences and amenities.",
  },
  {
    year: 2021,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "WNBA Championship Badges",
    details: "WNBA Championship Badges for WNBA Moments",
  },
  {
    year: 2021,
    month: "November",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "Mixi Chooses Flow for Games and NFTs",
    details:
      "Japanese media powerhouse mixi announces plans to launch NFT experiences on Flow blockchain, transforming how fans interact with digital content in Japan, following their success with Monster Strike.",
    link: "https://www.dapperlabs.com/newsroom/japanese-media-powerhouse-mixi-chooses-flow-blockchain-to-launch-new-games-and-nfts",
  },
  {
    year: 2021,
    month: "November",
    iconType: "DapperLabs",
    type: "New Product",
    title: "Method Man Releases Tical Universe Genesis NFT Collection",
    details:
      "Method Man collaborates with TuneGO to release his Tical Universe Genesis NFT Collection on Flow blockchain, featuring comic characters and new music ahead of his single release.",
    link: "https://www.dapperlabs.com/newsroom/method-man-releases-new-music-drops-tical-universe-tm-genesis-nft-collection",
  },

  {
    year: 2021,
    month: "November",
    iconType: "DapperLabs",
    type: "Partnership",
    title: "Gigster Partners with Dapper Labs for NFT Marketplace Development",
    details:
      "Gigster partners with Dapper Labs to accelerate NFT marketplace development on Flow blockchain, enabling improved collaboration for blockchain projects in the Flow ecosystem.",
    link: "https://www.dapperlabs.com/newsroom/leading-service-integrator-gigster-to-supercharge-marketplace-development-on-flow",
  },

  {
    year: 2021,
    month: "November",
    iconType: "DapperLabs",
    type: "New Product",
    title: "Real Madrid and LAVA Launch Smart Ticket NFTs",
    details:
      "Real Madrid and LAVA launch the first-ever Smart Ticket NFTs on Flow blockchain, allowing fans to collect and trade digital smart tickets that come with exclusive rewards. The launch event took place during the match at Santiago Bernabéu Stadium on September 28, 2021.",
    link: "https://www.dapperlabs.com/newsroom/real-madrid-and-lava-deliver-first-ever-smart-ticket-nft-experiences-on-flow-that-transform-fan-engagement-for-real-madrids-600-million-global-fans",
  },
  {
    year: 2021,
    month: "November",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Series 1 Reserve Pack Drop",
    details:
      "Opening the Vault: Series 1 Reserve Packs dropping on November 30, 2021. Collectors need 350 Trade Tickets to purchase these packs, which contain 4 Moments from Series 1.",
  },
  {
    year: 2021,
    month: "November",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with Infinite Objects",
    details:
      "NBA Top Shots can now be printed on Infinite Objects as unique physical video prints.",
  },
  {
    year: 2021,
    month: "November",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Flash Challenges",
    details:
      "The Peak: Collect Moments from top performers in key stats categories to complete a Showcase and earn a Series 2 Base Set Pack.",
  },
  {
    year: 2021,
    month: "November",
    iconType: "TopShot",
    type: "NewFeature",
    title:
      "Collection Sorting, Set Completion Badges, Purchase A Moment Flow, and Calendar Feature",
    details:
      "Product Upgrades: New collection sorting feature, set completion badges on profiles, enhanced 'Purchase A Moment' flow displaying Collector Score points, and new calendar feature for upcoming pack drop times.",
  },
  {
    year: 2021,
    month: "November",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Burn Event",
    details:
      "Burn Notice: Series 1 Rare Western and Eastern Conference Finals Moments permanently destroyed.",
  },
  {
    year: 2021,
    month: "November",
    iconType: "TopShot",
    type: "Issue",
    title: "Set Design Mistake",
    details:
      "WNBA 2021 Fandom Set Update: Design mistake addressed with increased Collector Scores and airdrop credits, along with priority queue access for WNBA drops in 2022.",
  },
  {
    year: 2021,
    month: "December",
    iconType: "DapperLabs",
    type: "New Product",
    title: "Dr. Seuss and Dapper Labs Launch Seussibles",
    details:
      "Dr. Seuss Enterprises, Dapper Labs, and Tibles launch Seussibles, a digital experience where fans can collect and trade NFTs of their favorite Dr. Seuss characters like The Cat in the Hat and The Lorax on Flow blockchain.",
    link: "https://www.dapperlabs.com/newsroom/dr-seuss-enterprises-dapper-labs-and-tibles-launch-seussibles",
  },

  {
    year: 2021,
    month: "December",
    iconType: "TopShot",
    type: "NewProduct",
    title: "Nine Lives Lounge Discord Launched",
    details:
      "Nine Lives Lounge Update: Exclusive Discord Lounge for Cool Cats set holders, with perks including early access to future projects and community-led decisions.",
  },
  {
    year: 2021,
    month: "December",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Community Gift Back of 5% of all-time net spend",
    details:
      "Community Gift Back: 5% of all-time net spend on the Marketplace returned as Dapper credit, as a thank you to early supporters.",
  },
  {
    year: 2021,
    month: "December",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "VIP Experience in San Francisco",
    details:
      "VIP Weekend in San Francisco: First VIP Collector event held, with in-person and virtual experiences planned to expand globally.",
  },
  {
    year: 2021,
    month: "December",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "Christmas Game Experience",
    details:
      "Access Unlocked: NBA Christmas Day Game: 7 collectors win a trip to watch the Warriors vs. Suns on Dec. 25 in Phoenix.",
  },
  //{
  //  year: 2022,
  //  month: "January",
  //  iconType: "NFLAD",
  //  type: "NFL All Day Launch",
  //   details:
  //     "NFL All Day: Moments now available from the NFL on the Flow Blockchain.",
  // },
  {
    year: 2022,
    month: "January",
    iconType: "DapperLabs",
    type: "New Product",
    title: "Dapper Labs and UFC Launch 'UFC Strike'",
    details:
      "Dapper Labs and UFC launch 'UFC Strike,' a digital collectible experience featuring iconic Moments and highlights from epic UFC fights. The first pack drop, 'Fully Loaded,' includes 100,000 packs showcasing top UFC fighters like Francis Ngannou and Amanda Nunes.",
    link: "https://www.dapperlabs.com/newsroom/dapper-labs-and-ufc-launch-ufc-strike-an-all-new-nft-experience-for-mma-fans-everywhere",
  },

  {
    year: 2022,
    month: "January",
    iconType: "TopShot",
    type: "Partnership",
    title: "Kevin Durant Partnership Campaign",
    details:
      "The Kevin Durant NBA Top Shot Takeover Starts Now (Jan 18): Announcement of Kevin Durant's collaboration with NBA Top Shot, including exclusive curated Starter Packs and insights into KD's career Moments.",
  },
  {
    year: 2022,
    month: "January",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Watchlist",
    details:
      "Introduction of the Watchlist Feature (Jan 29): NBA Top Shot introduces the 'Watchlist' feature, allowing users to track their favorite Moments and observe key community trends.",
  },
  {
    year: 2022,
    month: "February",
    iconType: "DapperLabs",
    type: "New Product",
    title: "Dapper Labs Launches Limited Edition AFC/NFC Champion NFTs",
    details:
      "Dapper Labs releases limited edition NFTs to commemorate the 2021 AFC and NFC Champions (Cincinnati Bengals and Los Angeles Rams) and the Super Bowl LVI champion. These exclusive collectibles include team logos, scores, and visuals of the respective conference trophies.",
    link: "https://www.dapperlabs.com/newsroom/dapper-labs-announces-limited-edition-afc-nfc-conference-champion-nfts-featuring-nfl-clubs-cincinnati-bengals-and-los-angeles-rams-and-the-super-bowl-champions",
  },
  {
    year: 2022,
    month: "February",
    iconType: "DapperLabs",
    type: "New Product",
    title: "NBA and Dapper Labs Launch First-Ever NFT Auction",
    details:
      "Dapper Labs and the NBA launch the first-ever NFT auction on NBA Top Shot, offering 30 unique NFTs representing every NBA team. The NFT owners receive VIP passes to the next five NBA All-Stars, starting with NBA All-Star 2023 in Salt Lake City.",
    link: "https://www.dapperlabs.com/newsroom/nba-and-dapper-labs-to-launch-first-ever-nft-auction-on-nba-top-shot-giving-fans-access-to-the-ultimate-all-star-vip-experience",
  },

  {
    year: 2022,
    month: "February",
    iconType: "TopShot",
    type: "NewFeature",
    title: "New 1/1 VIP All-Star Passes Auction",
    details:
      "NBA Top Shot and NBA partnered to create 1-of-1 NFTs for a unique experience, including the first auction and VIP Pass NFTs for All-Star events, providing exclusive access to All-Star events for the next five years.",
  },

  {
    year: 2022,
    month: "March",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Locker Room Upgrade",
    details:
      "NBA Top Shot Locker Room upgrade: Serves as a permanent sink for Moment NFTs, generating Trade Tickets for Stress Test pack drops.",
  },
  {
    year: 2022,
    month: "March",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Moments to Non-Custodial Wallets",
    details:
      "Expanded non-custodial wallet support: Moments can now be sent to approved non-custodial wallets like BloctoBay, VIV3, Versus, and GAIA.",
  },
  {
    year: 2022,
    month: "March",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "Warriors Leaderboard Access Unlocked Event",
    details:
      "Access Unlocked event: 7 collectors from the Warriors Leaderboard and 1 new collector will attend an NBA Playoffs game in Memphis with exclusive experiences.",
  },
  {
    year: 2022,
    month: "May",
    iconType: "Flow",
    type: "Funding",
    title: "Flow Launches $725 Million Ecosystem Fund",
    details:
      "Flow announces a $725 million Ecosystem Fund to drive innovation and support developers building on the Flow blockchain. The fund aims to accelerate product development, team expansion, and user acquisition across various sectors including gaming and decentralized finance.",
    link: "https://www.dapperlabs.com/newsroom/flow-launches-725-million-ecosystem-fund-to-drive-innovation-across-the-flow-ecosystem",
  },

  {
    year: 2022,
    month: "May",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with Doris Burke",
    details:
      "Doris Burke partnership: Adds her iconic commentary to selected NBA Playoff Moments, enhancing the collector experience with unique insights.",
  },
  {
    year: 2022,
    month: "May",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Top Shot Score",
    details:
      "Top Shot Score introduced: Replaces Collector Score, determining access to exclusive rewards, with Leaderboards added for high scorers.",
  },
  {
    year: 2022,
    month: "May",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "Access Unlocked Event - Playoff Game Tickets",
    details:
      "Access Unlocked event: 10 collectors won tickets to every playoff game, with trips to Toronto, San Francisco, and Dallas, including NBA Finals Game 2 in San Francisco.",
  },
  {
    year: 2022,
    month: "May",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with Magic Johnson",
    details:
      "Magic Johnson Anthology: A new pack drop featuring Legendary and Rare Moments of Magic Johnson, highlighting iconic plays from Lakers Championship seasons.",
  },
  {
    year: 2022,
    month: "June",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Nine Lives Lounge Council Extension with $10,000 monthly budget",
    details:
      "Nine Lives Lounge (NLL) Council extended through 2022 with a $10,000 monthly budget, controlling Cool Cats Unopened Pack Inventory.",
  },
  {
    year: 2022,
    month: "June",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Crafting Challenges",
    details:
      "Introduction of the Crafting Challenges, allowing collectors to permanently reduce the supply of specific Moments by burning them to earn new Moments.",
  },
  {
    year: 2022,
    month: "June",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "Vegas x Cool Cats Event",
    details:
      "NBA Top Shot: Vegas x Cool Cats event in Las Vegas on July 15 and 16.",
  },
  {
    year: 2022,
    month: "June",
    iconType: "TopShot",
    type: "IRLEvent",
    title:
      "NBA Draft Experience in New York and Meet-and-Greet with Paolo Banchero",
    details:
      "NBA Draft experience in New York, including exclusive meet-and-greet with Paolo Banchero for sweepstakes winners.",
  },
  {
    year: 2022,
    month: "June",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Magic Johnson Signs NBA Top Shot Moment",
    details:
      "The Anthology: Magic Johnson pack drop on June 7, introducing the first autographed NBA Top Shot Moment with Magic Johnson's 1980 Legendary Moment.",
  },
  {
    year: 2022,
    month: "June",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Offers",
    details:
      "Offers feature introduced, allowing users to make offers on any Moment in the NBA Top Shot Marketplace.",
  },
  {
    year: 2022,
    month: "June",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "Sylvia Fowles Final Regular Season Homegame Experience",
    details:
      "Special Access Unlocked experience for Sylvia Fowles' final regular season home game in Minneapolis, including game tickets, hotel stay, and dining experiences.",
  },

  {
    year: 2022,
    month: "July",
    iconType: "TopShot",
    type: "NewFeature",
    title: "New Set for Team Moments - Squad Goals",
    details:
      "Squad Goals Set launched, emphasizing teamwork with Moments from Series 1, 2, and 3 players.",
  },
  {
    year: 2022,
    month: "July",
    iconType: "TopShot",
    type: "IRLEvent",
    title:
      "Las Vegas Experience with Richard Jefferson, Channing Frye, J.R. Smith",
    details:
      "NBA Top Shot's Las Vegas event from July 15-16 featured retired NBA champions Richard Jefferson, Channing Frye, and J.R. Smith, with exclusive experiences like Hoops Takeover and the Nine Lives Lounge Brunch.",
  },
  {
    year: 2022,
    month: "July",
    iconType: "TopShot",
    type: "Merchandise",
    title: "New Merchandise",
    details:
      "Introduction of new NBA Top Shot merchandise available during the Las Vegas event, focusing on collector engagement and exclusive memorabilia.",
  },
  {
    year: 2022,
    month: "July",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Team Leaderboards",
    details:
      "Launch of Team Leaderboards, allowing collectors to lock Moments for rewards. This feature incentivizes long-term engagement and rewards community participation.",
  },
  {
    year: 2022,
    month: "August",
    iconType: "DapperLabs",
    type: "NewProduct",
    title: "NFL, NFLPA, and Dapper Labs Launch NFL ALL DAY Worldwide",
    details:
      "NFL, NFLPA, and Dapper Labs officially launch NFL ALL DAY, the digital video highlight NFT platform, ahead of the 2022 NFL season. Fans worldwide can now buy, own, and trade NFL Moments featuring iconic plays. Patrick Mahomes joins as the athlete ambassador for the platform.",
    link: "https://www.dapperlabs.com/newsroom/nfl-nflpa-and-dapper-labs-launch-nfl-all-day-worldwide",
  },
  {
    year: 2022,
    month: "August",
    iconType: "DapperLabs",
    type: "NewProduct",
    title: "Seussibles! Expands Availability to Desktop and Mobile Web",
    details:
      "Seussibles!, a digital Dr. Seuss collectible platform created by Tibles Inc. in partnership with Dapper Labs, expands its availability to desktop and mobile web. Fans can now buy, trade, and organize collections of iconic Seuss characters like The Cat in the Hat and The Lorax.",
    link: "https://www.dapperlabs.com/newsroom/seussibles-expands-availability-to-desktop-and-mobile-web-experiences",
  },
  {
    year: 2022,
    month: "August",
    iconType: "Flow",
    type: "New Product",
    title: "Ticketmaster Launches NFT Capabilities on Flow Blockchain",
    details:
      "Ticketmaster introduces a new capability for event organizers to issue digital collectible NFTs to fans before, during, or after an event. Over 5 million NFTs have already been minted on the Flow blockchain, providing fans with digital keepsakes that extend the live event experience.",
    link: "https://www.dapperlabs.com/newsroom/nfl-expands-commemorative-ticket-nft-offerings-for-2022-season",
  },

  {
    year: 2022,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Edition and Serial Offers",
    details:
      "TopShot now has Edition and Serial offers live in the marketplace.",
  },
  {
    year: 2022,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "RSVP Drops",
    details:
      "Introduction of RSVP Drops for a streamlined pack drop process, allowing collectors to opt-in for a chance to purchase packs without entering a queue.",
  },
  {
    year: 2022,
    month: "September",
    iconType: "DapperLabs",
    type: "Event",
    title: "Inaugural NFT Day Brings Together Web3 Global Industry Leaders",
    details:
      "Dapper Labs, along with industry leaders like OpenSea, Animoca Brands, and Coinbase NFT, celebrates the first-ever NFT Day on September 20, 2022. The event marks the anniversary of the ERC-721 standard, allowing participants to claim a free commemorative NFT and attend in-person and virtual events to raise awareness of NFTs.",
    link: "https://www.dapperlabs.com/newsroom/inaugural-nft-day-commemorates-non-fungible-tokens",
  },

  {
    year: 2022,
    month: "September",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with Sue Bird",
    details:
      "Launch of Sue Bird’s Game Recognize Game Set featuring curated Rare Moments hand-picked by Sue Bird, celebrating her career and supporting gender justice through a $50,000 donation to the National Women’s Law Center.",
  },
  {
    year: 2022,
    month: "September",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "WNBA Finals Experience",
    details:
      "Access Unlocked experience offered to top collectors for the 2022 WNBA Finals, providing Game 4 tickets, flights, accommodation, and more. If Game 4 isn’t played, winners will attend the 2023 WNBA Season opener.",
  },

  {
    year: 2022,
    month: "September",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "'Cooking with Cade' Experience",
    details:
      "NBA Top Shot offered a 'Cooking with Cade' experience in Detroit for top Detroit Pistons collectors, including preseason game tickets, a meet & greet with Cade Cunningham, and suite access.",
  },
  {
    year: 2022,
    month: "October",
    iconType: "DapperLabs",
    type: "NewProduct",
    title:
      "LaLiga and Dapper Labs Unveil 'LaLiga Golazos' Digital Collectibles",
    details:
      "LaLiga and Dapper Labs introduce 'LaLiga Golazos,' a digital collectible platform that captures iconic football moments from 2005 to the present. Fans can register for the closed beta, with the first packs dropping on October 27, featuring moments from legendary rivalries such as ElClásico, the Madrid Derby, and more.",
    link: "https://www.dapperlabs.com/newsroom/laliga-and-dapper-labs-unveil-laliga-golazos-digital-collectibles",
  },
  {
    year: 2022,
    month: "October",
    iconType: "DapperLabs",
    type: "NewProduct",
    title:
      "Sanrio Partners with Dapper Labs and Tibles to Create an Aggretsuko Experience",
    details:
      "Sanrio partners with Dapper Labs and Tibles to launch 'Aggretsuko Tibles,' a digital collectible experience featuring exclusive content from the world of Aggretsuko. Fans can now collect, trade, and interact with digital trading cards on Flow, expanding their fandom with app-exclusive content and community engagement.",
    link: "https://www.dapperlabs.com/newsroom/sanrio-dapper-labs-and-tibles-create-aggretsuko-fan-experience",
  },

  {
    year: 2022,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Duos Moments",
    details:
      "Introduction of Dynamic Duos, the first Team Moments on Top Shot, showcasing 60 players from 2009-2022 with Highlight Reel Moments. These Moments are distributed based on Team Leaderboard rankings.",
  },
  {
    year: 2022,
    month: "October",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "VIP Experience at Magic Mansion",
    details:
      "Tapped In ft. Magic Johnson event, offering a VIP experience at Magic Mansion in Los Angeles for top collectors on Magic Johnson and Lakers Leaderboards.",
  },

  {
    year: 2022,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Parallel Moments",
    details:
      "Launch of Parallel Moments in Series 4, where Base Set Moments are upgraded with limited edition Parallels featuring custom artwork and scarcer mints.",
  },

  {
    year: 2022,
    month: "November",
    iconType: "TopShot",
    type: "NewFeature",
    title: "The Great NBA Top Shot Burn - 2.5 Million Moments Removed",
    details:
      "The Great NBA Top Shot Burn, removing 2.5 million Moments from circulation, including unreleased Series 1 Moments, to enhance the scarcity and value of remaining Moments.",
  },
  {
    year: 2022,
    month: "November",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Live Minting",
    details:
      "Introduction of live minting for quicker access to Moments and improved transparency with detailed announcements for upcoming releases.",
  },
  {
    year: 2022,
    month: "November",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "Tapped In Experience with Klay Thompson",
    details:
      "Tapped In Experience with Klay Thompson on December 3, 2022, offering suite tickets, a personalized holiday gift basket, and a private post-game hangout.",
  },
  {
    year: 2022,
    month: "November",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "NBA Top Shot Tour stops in Portland, Toronto, Miami, and Paris",
    details:
      "NBA Top Shot Tour stops in Portland, Toronto, Miami, and Paris, featuring VIP events, limited edition collectibles, and opportunities to meet players.",
  },
  {
    year: 2022,
    month: "November",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "Fan Communities and Captains Program",
    details:
      "Overview of Fan Communities and Captains Program, organizing events and engaging fans, with over 1,100 collectors attending games for free in the first 30 days of the season.",
  },
  {
    year: 2022,
    month: "November",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Creation of 31 1/1 Celebrating the NBA 75th Anniversary",
    details:
      "Partnership with the NBA for 31 exclusive 1/1 digital collectibles celebrating the league's 75th anniversary, offering unique VIP benefits tied to future NBA All-Star events.",
  },

  {
    year: 2022,
    month: "December",
    iconType: "TopShot",
    type: "NewFeature",
    title: "12 Days of Hoopsmas event",
    details:
      "12 Days of Hoopsmas event encourages collectors to send Moments as gifts, with daily prizes including NBA Store gift cards, Infinite Objects displays, and an NBA Jam Arcade Machine.",
  },

  {
    year: 2022,
    month: "December",
    iconType: "TopShot",
    type: "IRLEvent",
    title:
      "NBA Top Shot Tour stops in Toronto, featuring an event with former Raptors player José Calderón",
    details:
      "NBA Top Shot Tour stops in Toronto, featuring an event with former Raptors player José Calderón and exclusive Fandom packs for attendees.",
  },

  {
    year: 2022,
    month: "December",
    iconType: "TopShot",
    type: "IRLEvent",
    title:
      "Orlando Magic Fan Community celebrates a fan's first NBA game after 32 years",
    details:
      "Orlando Magic Fan Community celebrates a fan's first NBA game after 32 years, highlighting the Magic fandom's inclusivity and efforts to provide unique experiences to international members.",
  },

  {
    year: 2023,
    month: "January",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "NBA Top Shot Tour in Miami",
    details:
      "The NBA Top Shot Tour comes to Miami, featuring meet-and-greet events with Glen Rice, VIP experiences, and exclusive Fandom Top Shot Debut Moments for event attendees.",
  },

  {
    year: 2023,
    month: "January",
    iconType: "TopShot",
    type: "NewFeature",
    title: "All 7,962 Platinum Ice Moments were successfully Burned",
    details:
      "All 7,962 Platinum Ice Moments were successfully burned, enhancing the collectibility of remaining Moments.",
  },

  {
    year: 2023,
    month: "January",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "NBA Top Shot Tour in Paris",
    details:
      "The NBA Top Shot Tour continues in Paris, including special events and collectible Souvenir Packs for attendees, as well as a new Locations feature to connect collectors worldwide.",
  },
  {
    year: 2023,
    month: "January",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Top Shot 50 Set - Collaboration with the NBPA",
    details:
      "The Top Shot 50 First Wave is revealed, celebrating 10 standout players and introducing voting for Legendary plays.",
  },

  {
    year: 2023,
    month: "January",
    iconType: "TopShot",
    type: "NewFeature",
    title: "User Locations on Profile",
    details: "Introducing of a Locations feature on users profiles.",
  },
  {
    year: 2023,
    month: "January",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Editor's Picks Marketplace",
    details:
      "Introducing the Editor's Picks Marketplace, reimagining how users discover and collect NBA history with curated selections of top NBA Moments.",
  },
  {
    year: 2023,
    month: "February",
    iconType: "TopShot",
    type: "NewProduct",
    title: "Launch of NBA Top Shot iOS app",
    details:
      "NBA Top Shot introduced its official mobile app, offering a personalized experience with 3D interactive Moments, enhanced data visualization, and more. All collectors can join the waitlist to gain access.",
  },

  {
    year: 2023,
    month: "March",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Predictive Challenges",
    details:
      "Introduction of Predictive Challenges during the 2023 All-Star Game Weekend, allowing fans to earn prizes based on predictions (March 10).",
  },

  {
    year: 2023,
    month: "March",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Challenge Tiers and Challenge Gating",
    details:
      "Introduction of Challenge Tiers and Challenge Gating to create varied entry points for collectors based on collection type (March 10).",
  },

  {
    year: 2023,
    month: "March",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with HOMAGE for Limited Edition Top Shot Debut Shirt",
    details:
      "Top Shot partnered with HOMAGE for a limited-edition Top Shot Debut T-Shirt, with 2,500 collectors receiving it for free through a drawing (March 17).",
  },

  {
    year: 2023,
    month: "April",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Redemptions for the 2023 NBA Playoffs",
    details:
      "Introduction of Redemptions for the 2023 NBA Playoffs, allowing collectors to secure and redeem exclusive Moments from each playoff round (April 11).",
  },

  {
    year: 2023,
    month: "April",
    iconType: "TopShot",
    type: "NewProduct",
    title: "NBA Top Shot App released for iOS and Google Play Stores",
    details:
      "NBA Top Shot App released for iOS and Google Play Stores, featuring personalized mobile-first experiences for managing collections, notifications, and social sharing during the 2023 NBA Playoffs (April 25).",
  },

  {
    year: 2023,
    month: "April",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "NBA Finals Experience",
    details:
      "NBA Finals Experience Sweepstakes ongoing, offering a chance to win an all-expense paid trip to the 2023 NBA Finals by purchasing a Pack in April (April 12).",
  },

  {
    year: 2023,
    month: "May",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Team Tags",
    details:
      "Team Tags introduced to commemorate teams' progress in the 2023 NBA Playoffs, allowing fans to earn and showcase Tags like Conference Semifinalist, Conference Finalist, Conference Champion, and NBA Champion (May 2).",
  },

  {
    year: 2023,
    month: "May",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "NBA Draft Experience",
    details:
      "Win a Trip to the NBA Draft on June 22, with opportunities to witness the new rookie class and receive an airdrop of the First Overall Pick's Top Shot Debut Moment (May 8).",
  },
  {
    year: 2023,
    month: "May",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "Vegas Summer League Experience",
    details:
      "NBA Top Shot in Las Vegas from July 7-9 during Summer League, providing community involvement opportunities and events (May 26). NBA Top Shot team participated in NBA Con and Summer League in Las Vegas, including a Fireside Chat with Roham and Adam Silver discussing Top Shot’s future and upcoming features (July 8).",
  },

  {
    year: 2023,
    month: "May",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Launch of the 2023 Collector Panel",
    details:
      "Launch of the 2023 Collector Panel which will give members of our community a formal and direct line to different members of our team across roles and responsibilities, and the opportunity to review and provide feedback on potential plans for the future.",
  },

  {
    year: 2023,
    month: "June",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "NBA Con and NBPA Brotherhood Deli events",
    details:
      "NBA Con and NBPA Brotherhood Deli events scheduled in Las Vegas during the first weekend of Summer League, offering collectors opportunities to engage with the NBA Top Shot community (June 27).",
  },

  {
    year: 2023,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Collection Organization and Profiles",
    details:
      "Enhancements to collection organization and profiles in progress, including sorting by Top Shot Score and displaying fan identity (Aug 30).",
  },
  {
    year: 2023,
    month: "August",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Temporary Locks on Moments",
    details:
      "New version of Burn Leaderboard technology introduced, featuring temporary locks on Moments.",
  },

  {
    year: 2023,
    month: "August",
    iconType: "TopShot",
    type: "Partnership",
    title: "Partnership with Larry Bird",
    details:
      "Larry Bird’s Anthology Set launched, featuring classic highlights from Larry Bird's career.",
  },

  {
    year: 2023,
    month: "September",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Commercial Rights to Set Art",
    details:
      "Commercial rights to Set Art introduced for collectors completing qualifying Sets, allowing them to use, copy, and display Set Art for creating merch without sharing profits with Top Shot (Sep 19).",
  },
  {
    year: 2023,
    month: "September",
    iconType: "TopShot",
    type: "NewFeature",
    title:
      "Introduction of in-game Arena and Crowd Audio for Legendary Moments",
    details:
      "Introduction of in-game arena and crowd audio for Legendary Moments.",
  },
  {
    year: 2023,
    month: "September",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Introduction of new Rookie of the Year Badge",
    details: "Introduction of new Rookie of the Year Badge.",
  },

  {
    year: 2023,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Pack Marketplace Opened",
    details:
      "The Pack Marketplace opened, allowing collectors to sell unopened Packs within the Top Shot community, with various Pack sets becoming eligible for listing and purchasing (Oct 2).",
  },

  {
    year: 2023,
    month: "October",
    iconType: "TopShot",
    type: "NewProduct",
    title: "Fast Break Announceed",
    details:
      "A new game tying collections to real NBA performances was announced, offering early access via email to the upcoming experience (Oct 26).",
  },
  {
    year: 2023,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "3rd Birthday",
    details:
      "Celebration of NBA Top Shot's third birthday with the release of Series 1 Packs and a special Happy Birthday Pack, along with giveaways and challenges (Oct 2). NBA Top Shot's third birthday celebration with collectors and NBA fans worldwide, including giveaways on Twitter, Discord, and Reddit (Oct 2).",
  },

  {
    year: 2023,
    month: "October",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Captains Program launched",
    details:
      "The Captains Program launched, where Team Captains lead 30 Fan Communities, facilitating giveaways and meet-ups, with a portion of Marketplace sales going to community budgets (Oct 27).",
  },
  {
    year: 2023,
    month: "November",
    iconType: "DapperLabs",
    type: "New Product",
    title:
      "Dapper Labs Announces Disney Pinnacle, A First-Of-Its-Kind Digital Pin Collecting and Trading Experience",
    details:
      "Dapper Labs launches 'Disney Pinnacle,' a digital pin collecting and trading platform featuring characters from Disney, Pixar, and Star Wars. Fans can collect, trade, and showcase digital pins, with a waitlist open today for the iOS app's upcoming release.",
    link: "https://www.dapperlabs.com/newsroom/dapper-labs-announces-disney-pinnacle",
  },

  {
    year: 2023,
    month: "November",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Offers and Mobile Marketplace",
    details:
      "New Offers feature updates, with improved sorting and separate tabs for Edition and Serial level offers, along with the Mobile Marketplace gradually expanding access (Nov 3).",
  },
  {
    year: 2023,
    month: "November",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Introduction of 1/1 Ultimate Moments",
    details:
      "Introduction of 1/1 Ultimate Moments for up to 10 rookies, with collectible badges and highlight reels (Nov 1).",
  },

  {
    year: 2023,
    month: "December",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Weighted Airdrops",
    details:
      "Weighted airdrops introduced for collectors on relevant Team and Player Leaderboards, providing consistent reward opportunities (Dec 1).",
  },
  {
    year: 2023,
    month: "December",
    iconType: "TopShot",
    type: "NewFeature",
    title: "WNBA Burn Event",
    details:
      "Unreleased WNBA Moments set to be burned soon, excluding special serial numbers (Dec 1).",
  },
  {
    year: 2023,
    month: "December",
    iconType: "TopShot",
    type: "NewProduct",
    title: "Fastbreak Launched",
    details:
      "New game 'Fast Break' developed to engage with live NBA action using Moments, with a full rollout expected in 2024 (Dec 1).",
  },

  {
    year: 2024,
    month: "March",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Guest Checkout",
    details:
      "Introduction of Guest Checkout (Mar 6): New 'Guest checkout' feature added for new collectors, allowing easier access and onboarding to the platform.",
  },
  {
    year: 2024,
    month: "March",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Lock Favourite Team",
    details:
      "Lock Favourite Team Feature (Mar 6): Announcement of the ability to lock a Favourite Team, to be added before the end of the NBA season.",
  },

  {
    year: 2024,
    month: "May",
    iconType: "TopShot",
    type: "NewFeature",
    title: "Volume-Based Leaderboards",
    details:
      "Introduction of Volume-Based Leaderboards (May 16): New leaderboards focused on lower-end Moments, providing utility and unique prizing through Flash Leaderboards and Quick Rip Burn Leaderboards.",
  },

  {
    year: 2024,
    month: "June",
    iconType: "TopShot",
    type: "IRLEvent",
    title: "Ticket Giveaway for NBA Finals",
    details:
      "NBA Top Shot hosted a giveaway for NBA Finals tickets, providing a unique in-person experience for the winners.",
  },
];
